import { useForm, Form, Input, Row, Col, Create, Select, useSelect, InputNumber, Switch } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import React from "react";
import { AnswerListComponent } from "./AnswerListComponent";
import { operatorOpts, typeOps } from "./edit";
import { optionsGender } from "pages/Auth/SocialRegister/SocialRegister";
import { CardAdmin } from "components/Card/CardAdmin";

export const QuestionCreate: React.FC = () => {
  const { push } = useNavigation();
  const { selectProps } = useSelect({
    resource: "question",
    optionLabel: "description",
    pagination: {
      pageSize: 1000,
    },
    filters: [
      {
        field: "parentQuestionId",
        operator: "eq",
        value: null,
      },
    ],
  });

  const { formProps, saveButtonProps } = useForm({
    resource: "question",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/question");
    },
  });

  const onFinish = React.useCallback(
    (e: any) => {
      console.log({ e });
      formProps?.onFinish?.({
        name: e.name,
        description: e.description,
        type: e.type,
        answer_detail: {
          items: e.answer_detail,
        },
        result: {
          answers: [],
        },
        question_source: {
          source: e.question_source_source,
          description: e.question_source_description,
          additional: e.question_source_additional,
        },
        metadata: e.metadata,
      });
    },
    [formProps],
  );

  const enabledAgeToShow = Form.useWatch(["metadata", "ageToShow", "enabled"]);

  return (
    <Create saveButtonProps={saveButtonProps} title="Create question">
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Row gutter={[20, 0]} wrap>
          <Col xs={24} lg={8}>
            <div className="mt-3 border border-gray-300 border-solid p-4">
              <h3>General Infomation</h3>
              <Form.Item
                label={"No."}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label={"Type"}
                name="type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={typeOps} placeholder="Type" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <CardAdmin title="Question Source">
              <Form.Item label={"Source"} name={["question_source", "source"]}>
                <Input />
              </Form.Item>
              <Form.Item label={"Additional"} name={["question_source", "additional"]}>
                <Input />
              </Form.Item>
              <Form.Item label={"Question source description"} name={["question_source", "description"]}>
                <Input.TextArea />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={8}>
            <div className="mt-3 border border-gray-300 border-solid p-4">
              <h3>Question Group</h3>

              <Form.Item label={"Parent question"} name="parentQuestionId" className="w-full">
                <Select {...selectProps} allowClear placeholder="Questions" />
              </Form.Item>
            </div>
            <div className="mt-3 border border-gray-300 border-solid p-4">
              <h3>Metadata</h3>
              <div className="mt-3 border border-gray-300 border-solid p-4">
                <div className="flex w-full items-center  space-x-2 mb-2">
                  <h3 className="mb-0">Requirements</h3>

                  <Form.Item name={["metadata", "ageToShow", "enabled"]} valuePropName="checked" className="mb-0">
                    <Switch defaultChecked={false} className="mb-0" />
                  </Form.Item>
                </div>
                <div className="flex space-x-2">
                  <Form.Item
                    label={"Age"}
                    name={["metadata", "ageToShow", "age"]}
                    className="w-1/3"
                    rules={[
                      {
                        required: Boolean(enabledAgeToShow),
                      },
                    ]}
                  >
                    <InputNumber disabled={!Boolean(enabledAgeToShow)} min={0} />
                  </Form.Item>
                  <Form.Item
                    label={"Gender"}
                    name={["metadata", "ageToShow", "gender"]}
                    className="w-2/3"
                    rules={[
                      {
                        required: Boolean(enabledAgeToShow),
                      },
                    ]}
                  >
                    <Select mode="multiple" allowClear options={optionsGender} disabled={!Boolean(enabledAgeToShow)} />
                  </Form.Item>
                </div>
                <Form.Item
                  label={"Operator"}
                  name={["metadata", "ageToShow", "operator"]}
                  className="w-[200px]"
                  rules={[
                    {
                      required: Boolean(enabledAgeToShow),
                    },
                  ]}
                >
                  <Select options={operatorOpts} disabled={!Boolean(enabledAgeToShow)} />
                </Form.Item>
              </div>

              <Form.Item
                label={"Free score"}
                name={["metadata", "freeScore"]}
                className="w-full mt-2"
                valuePropName="checked"
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} lg={24}>
            <AnswerListComponent form={formProps.form} />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
