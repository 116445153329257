import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import React, { createContext, ReactNode, useContext } from "react";
import { config } from "config";
import { CrudFilters, useApiUrl, useCustom, useList } from "@pankod/refine-core";
import { IUser, Role } from "types/user";
import { IScore, IUserQuestion } from "types/user-question";
import { IDimension } from "types/dimension";
import { groupBy, map, sumBy } from "lodash-es";
import { LoadingFullPage } from "components/Loading/FullPage";

type MeContextProps = {
  me?: IUser;
  myHRA?: IUserQuestion;
  dimensions?: IDimension[];
  worstDimensions?: IDimension[];
  refetch: () => void;
  totalHRA: IScore[];
  totalUsers: number;
  totalScore: IScore[];
  myRole?: Role;
  isSuperAdmin?: boolean;
  isMeLoading?: boolean;
  isMember?: boolean;
  isAdmin?: boolean;
  isManagement?: boolean;
  isGuest?: boolean;
  isSuccess?: boolean;
  company?: any;
};

const MeContext = createContext<MeContextProps>({} as MeContextProps);

export const useMe = () => {
  return useContext(MeContext);
};

type Props = {
  children: ReactNode;
};

export const MeProvider = ({ children }: Props) => {
  const apiUrl = useApiUrl();
  const [tokens] = useLocalStorage<any>(config.TOKEN_KEY);
  const { data, refetch, isLoading } = useCustom<IUser>({
    url: `${apiUrl}/auth/profile`,
    method: "get",
    config: {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    },
  });
  const me = data?.data;
  const isMeLoading = isLoading;
  const now = new Date();
  const myRole = me?.role;
  const isSuperAdmin = me?.isOps;
  const isAdmin = myRole === Role.ADMIN;
  const isMember = myRole === Role.MEMBER;
  const isManagement = myRole === Role.MANAGEMENT;
  const isGuest = myRole === Role.GUEST;
  // const yearStart = now.getFullYear();
  const yearStart = 2023; // will be removed when 2025 comes in
  const filterCreatedAt: CrudFilters = [
    {
      field: "createdAt",
      operator: "gte",
      value: `${yearStart}-01-01`,
    },
    {
      field: "createdAt",
      operator: "lte",
      value: `${now.getFullYear()}-12-31`,
    },
  ];

  const { data: dataHRA, isSuccess } = useList<IUserQuestion>({
    resource: "user-question",
    config: {
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1,
      },
      filters: [
        {
          field: "userId",
          operator: "eq",
          value: me?.id,
        },
        ...filterCreatedAt,
      ],
    },
    queryOptions: {
      enabled: Boolean(me?.id),
    },
  });
  const myHRA = dataHRA?.data[0];
  const { data: dimensionData } = useList<IDimension>({
    resource: "dimension",
  });
  const dimensions = dimensionData?.data;
  const totalScore = React.useMemo(() => {
    return myHRA?.result.total || [];
  }, [myHRA?.result.total]);
  const totalScoreSorted = totalScore?.sort((a, b) => a.scoring - b.scoring);
  const leastScores = totalScoreSorted.slice(0, 3).map((item) => item.dimensionId);
  const worstDimensions = dimensions?.filter((item) => leastScores?.includes(item.id));

  const { data: dataHRATotal } = useList<IUserQuestion>({
    resource: "user-question",
    config: {
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 99999,
      },
    },
  });
  const totalUsers = dataHRATotal?.total || 0;
  const totalHRA: IScore[] = React.useMemo(() => {
    return (
      map(groupBy(dataHRATotal?.data.flatMap((item) => item.result.total), (item) => item.dimensionId)).map((item) => {
        return {
          dimensionId: item[0].dimensionId,
          scoring: sumBy(item, "scoring") / item.length,
        };
      }) || []
    );
  }, [dataHRATotal?.data]);

  const value = React.useMemo(() => {
    return {
      me,
      myHRA,
      dimensions,
      worstDimensions,
      refetch,
      totalHRA,
      totalUsers,
      totalScore,
      isSuperAdmin,
      myRole,
      isMeLoading,
      isMember,
      isAdmin,
      isManagement,
      isGuest,
      isSuccess,
      company: me?.company,
    };
  }, [
    me,
    myHRA,
    dimensions,
    worstDimensions,
    refetch,
    totalHRA,
    totalUsers,
    totalScore,
    isSuperAdmin,
    myRole,
    isMeLoading,
    isMember,
    isAdmin,
    isManagement,
    isGuest,
    isSuccess,
  ]);
  if (isLoading) return <LoadingFullPage />;

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};
