import { DataType } from "./challenge";

export enum ModeTime {
  WEEKLY = "Weekly",
  DAILY = "Daily",
}

export interface ILevelActivity {
  name: string;
  percent: number;
  selected?: boolean;
  data_type: DataType;
  results: any;
}

export interface IActivity {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  order: number;
  type: ModeTime;
  isRecommend: boolean;
  isHide: boolean;
  id: string;
  levelActivities: ILevelActivity[];
  icon: string;
  percent: number;
  dimensionId: string;
}
