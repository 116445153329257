export const chartColorOptions = [
  {
    value: "nivo",
    label: "Nivo",
  },
  {
    value: "category10",
    label: "Category 10",
  },
  {
    value: "accent",
    label: "Accent",
  },
  {
    value: "dark2",
    label: "Dark 2",
  },
  {
    value: "paired",
    label: "Paired",
  },
  {
    value: "pastel1",
    label: "Pastel 1",
  },
  {
    value: "pastel2",
    label: "Pastel 2",
  },
  {
    value: "set1",
    label: "Set 1",
  },
  {
    value: "set2",
    label: "Set 2",
  },
  {
    value: "set3",
    label: "Set 3",
  },
  {
    value: "brown_blueGreen",
    label: "Brown -> Blue Green",
  },
  {
    value: "purpleRed_green",
    label: "Purple Red -> Green",
  },
  {
    value: "pink_yellowGreen",
    label: "Pink -> Yellow Green",
  },
  {
    value: "purple_orange",
    label: "Purple -> Orange",
  },
  {
    value: "red_blue",
    label: "Red -> Blue",
  },
  {
    value: "red_grey",
    label: "Red -> Grey",
  },
  {
    value: "red_yellow_blue",
    label: "Red -> Yellow -> Blue",
  },
  {
    value: "red_yellow_green",
    label: "Red -> Yellow -> Green",
  },
  {
    value: "spectral",
    label: "Spectral",
  },
  {
    value: "blues",
    label: "Blues",
  },
];
