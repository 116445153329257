import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCircleUser, faBell } from "@fortawesome/free-solid-svg-icons";
import "./forums.scoped.scss";
import { useList } from "@pankod/refine-core";
import React from "react";
import { Link } from "@pankod/refine-react-router-v6";
import { useCompany } from "context/CompanyContext";
import dayjs from "dayjs";
import { css } from "@emotion/react";

export const ForumTable: React.FC<{ category: any }> = ({ category }) => {
  const { path } = useCompany();
  const { data } = useList({
    resource: "topic",
    config: {
      filters: [
        {
          field: "category_id",
          operator: "eq",
          value: category.id,
        },
      ],
      sort: [
        {
          field: "order",
          order: "asc",
        }
      ]
    },
  });
  const topics = data?.data;
  // console.log("topics:", data)
  return (
    <div className="table-wrapper">
      <table className="forums-table">
        <tbody>
          <tr>
            <th className="table-title" colSpan={4}>
              {category.name}
            </th>
          </tr>
          {topics?.map((topic) => {
            const url = `/${path}/forums/topic/${topic?.id}`;
            return (
              <tr
                className=""
                css={css`
                  th,
                  td {
                    padding: 12px 20px !important;
                  }
                `}
                key={topic.id}
              >
                <th
                  className="left-column"
                  css={css`
                    position: relative;
                    background-color: white !important;
                  `}
                >
                  <div
                    className="left-column-content"
                    css={css`
                      background-color: white !important;
                      margin: 12px 0px 12px 0px !important;
                    `}
                  >
                    <span
                      className="icon-column"
                      css={css`
                        margin: 0 15px auto auto;
                        font-size: 25px;
                        position: absolute;
                        top: 35%;
                        @media screen and (max-width: 768px) {
                          display: none !important;
                        }
                      `}
                    >
                      <FontAwesomeIcon icon={["far", "message"]} className="icon-message" />
                    </span>
                    <span
                      css={css`
                        margin: 0 0 0 40px;
                        padding: 0 !important;
                        font-weight: 550;
                        letter-spacing: 0.1px;
                        font-size: 16px;
                        float: left;
                      `}
                      className="topic-name"
                    >
                      <Link to={url} className="underline-hover">
                        {topic.name}
                      </Link>
                    </span>
                  </div>
                </th>
                <TopicCell topicId={topic.id as string} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TopicCell: React.FC<{ topicId: string }> = ({ topicId }) => {
  const { path } = useCompany();
  const { data } = useList({
    resource: "post",
    config: {
      filters: [
        {
          field: "topic_id",
          operator: "eq",
          value: topicId,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    metaData: {
      join: {
        field: "user",
      },
    },
  });
  const post = data?.data[0];
  const name = (post?.name || "").substring(0, 27);
  const total = data?.total;
  const url = `/${path}/forums/topic/${topicId}/post/${post?.id}`;
  return (
    <>
      <td className="align-center mid-column">
        Posts
        <div className="threads-number">{total}</div>
      </td>
      <td
        className="last-column"
        css={css`
          opacity: ${post ? "1" : "0"};
        `}
      >
        {name && (
          <>
            {
              <Link to={url} className="underline-hover">
                {name}
              </Link>
            }{" "}
            {post?.name?.length > 27 ? <span>...</span> : ""}
          </>
        )}
        <div>
          {dayjs(post?.createdAt).format("MMM DD, YYYY")}
          <span
            className="black-text"
            css={css`
              text-decoration: none;
              color: yellow !important;
            `}
          >
            <FontAwesomeIcon icon={faUser} className="user-icon" />
            {post?.user?.firstname}
          </span>
        </div>
      </td>
    </>
  );
};
