import React from "react";
import "./forums.scoped.scss";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { useMe } from "context/MeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMessage, faCircleUser, faBell } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useList } from "@pankod/refine-core";
import { ForumTable } from "./ForumTable";
import dayjs from "dayjs";
import { Link,useParams } from "@pankod/refine-react-router-v6";
import { ForumLayout } from "components/Layout/Forum/forum-layout";
library.add(far, faMessage);
library.add(fas, faBell);

const Forums: React.FC = () => {
  const { hra_summary, id, path } = useCompany();
  const { company, isSuperAdmin } = useMe();
  const { id: company_id } = useCompany();
  console.log("companyid", company?.id)
  const { data } = useList({
    resource: "category-forum",
    config: {
      filters: [
        {
          field: "company_id",
          operator: "eq",
          value: id,
        },
      ],
      sort: [
        {
          field: "order",
          order: "asc",
        },
      ],
    },
  });
  const categories = data?.data;
  const { data: dataPosts } = useList({
    resource: "post",
    config: {
      filters: [
        {
          field: "topic.category.company_id",
          operator: "eq",
          value: company_id,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 10,
      },
    },
    metaData: {
      join: [
        {
          field: "topic",
        },
        {
          field: "topic.category",
        },
        {
          field: "user",
        },
      ],
    },
  });
  const posts = dataPosts?.data;
  console.log("posts:::", posts)
  return (
    <ForumLayout>
      <div className="chat-page"
        css={css`
          th {
            background-color: whitesmoke;
            color: ${hra_summary?.main_hra_color};
          }
          .left-column {
            color: ${hra_summary?.main_hra_color};
            display: flex;
            justify-content: flex-start;
          }
          .last-column {
            color: ${hra_summary?.main_hra_color};
          }
          .last-column {
            span {
              color: ${hra_summary?.main_hra_color};
              .user-icon {
                font-size: 10px;
                //margin-bottom: 4px;
                margin-right: 5px;
              }
            }
          }
          .icon {
            font-size: 22px !important;
            font-weight: 100 !important;
          }
          .text {
            color: ${hra_summary?.main_hra_color} !important;
            font-weight: 550;
          }
          .line {
            width: 100%;
            border: 1px solid ${hra_summary?.main_hra_color};
            display: inline-block;
            margin: 20px 0 30px 0;
          }
        `}
      >
        <div className="container ">
          <div className="j-between main-content">
            <div className="left">
              {categories?.map((category) => {
                return <ForumTable key={category.id} category={category} />;
              })}
            </div>

            {/* LATEST POSTS */}
            <div className="right latest">
              <div className="table-wrapper">
                <table className="latest-table">
                  <tbody>
                    <tr>
                      <th className="latest-title">Latest posts</th>
                    </tr>
                    <tr>
                      {posts?.map((post) => {
                        const text = (post.name || "").substring(0, 25);
                        const url = `/${path}/forums/topic/${post.topic.id}/post/${post?.id}`;
                        return (
                          <td className="lastest-content" key={post.id}>
                            <span className="lastest-left">
                              <FontAwesomeIcon icon={faCircleUser} className="ava-icon" />
                            </span>
                            <span className="lastest-right">
                              <div className="text">
                                <Link to={url} className="underline-hover">
                                  {text}
                                </Link>
                                {post?.name?.length > 25 ? <span>...</span> : ""}
                              </div>
                              <div className="info">
                                {dayjs(post.createdAt).format("MMM DD, YYYY")} · {post.user.firstname}
                              </div>
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ForumLayout>
  );
};

export default Forums;
