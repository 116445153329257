import { useForm, Form, Input, Row, Col, Create, InputNumber } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { CardAdmin } from "components/Card/CardAdmin";
import React from "react";
import { ModeTime } from "types/activity";

export const activityOps = [
  {
    value: ModeTime.DAILY,
    label: ModeTime.DAILY,
  },
  {
    value: ModeTime.WEEKLY,
    label: ModeTime.WEEKLY,
  },
];

export const TrophyCreate: React.FC = () => {
  const { push } = useNavigation();
  const { formProps, saveButtonProps } = useForm({
    resource: "trophy",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/trophy");
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={12}>
            <CardAdmin title="General information">
              <Form.Item
                className="w-100"
                label={"Title"}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="w-100"
                label={"Rank"}
                name="rank"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Rank must be number and cannot be less than 0",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                className="w-100"
                label={"Trophy"}
                name="point"
                rules={[
                  {
                    required: true,
                    min: 0,
                    type: "number",
                    message: "Point must be number and cannot be less than 0",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
