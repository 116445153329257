import React, { useState } from "react";
import { Layout, Menu, Grid } from "antd";
import { LogoutOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  useTranslate,
  useLogout,
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useRouterContext,
  useMenu,
} from "@pankod/refine-core";
import logo from "assets/img/logologo.png";
import { css } from "@emotion/react";

import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { RefineLayoutSiderProps } from "@pankod/refine-ui-types";
import { useMe } from "context/MeContext";
const { SubMenu } = Menu;

export const Sider: React.FC<RefineLayoutSiderProps> = ({ render }) => {
  const { isSuperAdmin } = useMe();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { Link } = useRouterContext();
  const { mutate: mutateLogout } = useLogout();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <CanAccess
            key={route}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <SubMenu key={route} icon={icon ?? <UnorderedListOutlined />} title={label}>
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && <div className="ant-menu-tree-arrow" />}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const logout = isExistAuthentication && (
    <Menu.Item key="logout" onClick={() => mutateLogout()} icon={<LogoutOutlined />}>
      {translate("buttons.logout", "Logout")}
    </Menu.Item>
  );

  const dashboard = null;
  const whiteListItems = [
    "admin",
    "activity",
    "challenge",
    "icons",
    "hraadmin",
    "forum",
    "team",
    "location",
    "jobtitle",
    "employeetype",
    "metadata",
  ];
  console.log({ menuItems });
  const _menuItems = menuItems.filter((item) => {
    if (isSuperAdmin) return true;
    return whiteListItems.includes(item.name.toLocaleLowerCase());
  });
  const items = renderTreeView(_menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
        collapsed,
      });
    }
    return (
      <>
        {dashboard}
        {items}
        {logout}
      </>
    );
  };

  return (
    <Layout.Sider
      css={css`
        background: white !important ;
        .ant-layout-sider-trigger {
          background: #005e83;
          span {
            color: white !important;
          }
        }
        span {
          color: #005e83 !important;
          font-weight: 700;
        }
      `}
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <div className=" my-4 px-2">
        <img src={logo} alt="Monument Health logo" style={{ marginTop: "-1rem" }} />
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {renderSider()}
      </Menu>
    </Layout.Sider>
  );
};
