import { TableProps, useTable } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { groupBy, map, sumBy } from "lodash-es";
import React, { ReactNode, createContext, useContext } from "react";
import { IUser } from "types/user";

type ChallengeContextProps = {
  tablePersonalProps: TableProps<IUser>;
  tableTeamProps: TableProps<BaseRecord>;
  dataTeam?: any;
  dataIndividual?: any;
  onRefetch: () => void;
  dataRawTeam?: any[];
};

const ChallengeContext = createContext<ChallengeContextProps>({} as ChallengeContextProps);

export const useChallengeContext = () => {
  return useContext(ChallengeContext);
};

type Props = {
  children: ReactNode;
  selectedChallenge: string | number;
};

export const ChallengeProvider = ({ children, selectedChallenge }: Props) => {
  const { tableQueryResult: dataRawIndividual, tableProps: tablePersonalProps } = useTable<IUser>({
    resource: "user-challenge",
    initialSorter: [
      {
        field: "point",
        order: "desc",
      },
    ],
    permanentFilter: [{ field: "challenge_id", operator: "eq", value: selectedChallenge }],

    metaData: {
      join: {
        field: "user",
      },
    },
    initialPageSize: 5,
    syncWithLocation: false,
  });
  const onRefetchPersonal = React.useCallback(() => dataRawIndividual.refetch(), [dataRawIndividual]);
  const dataIndividual = dataRawIndividual.data?.data.map((item, key) => {
    return {
      ...item,
      rank: key + 1,
    };
  });

  const { tableQueryResult: dataRawTeam, tableProps: tableTeamProps } = useTable({
    resource: "user-challenge",
    initialSorter: [
      {
        field: "point",
        order: "desc",
      },
    ],
    metaData: {
      join: [
        {
          field: "team",
        },
        {
          field: "user",
        },
      ],
    },
    permanentFilter: [
      { field: "challenge_id", operator: "eq", value: selectedChallenge },
      { field: "mode_join", operator: "eq", value: "team" },
    ],
    initialPageSize: 100,
    syncWithLocation: false,
  });
  const onRefetchTeam = React.useCallback(() => dataRawTeam.refetch(), [dataRawTeam]);
  const _dataTeam = map(groupBy(dataRawTeam.data?.data, "teamId"), (item, teamId) => {
    return {
      teamId,
      name: item[0].team.name,
      point: sumBy(item, "point"),
    };
  });

  const dataTeam = _dataTeam
    .sort((a, b) => b.point - a.point)
    .slice(0, 4)
    .map((item, key) => {
      return {
        ...item,
        rank: key + 1,
      };
    });
  const onRefetch = React.useCallback(() => {
    onRefetchPersonal();
    onRefetchTeam();
  }, [onRefetchPersonal, onRefetchTeam]);
  const value = React.useMemo(() => {
    return {
      dataIndividual,
      dataTeam,
      tableTeamProps,
      tablePersonalProps,
      onRefetch,
      dataRawTeam: dataRawTeam.data?.data,
    };
  }, [dataIndividual, dataTeam, onRefetch, tablePersonalProps, tableTeamProps, dataRawTeam]);

  return <ChallengeContext.Provider value={value}>{children}</ChallengeContext.Provider>;
};
