import React from "react";
import "./password.scoped.scss";
import logo from "assets/img/logologo.png";
import jwtDecode from "jwt-decode";
import { Form } from "@pankod/refine-antd";
import { PasswordButton } from "components/Buttons/Buttons";
import { InputBorderBottomWrapper, InputPrimary } from "components/Input";
import { useCustomMutation, useNavigation, useOne } from "@pankod/refine-core";
import { ArrowLeftOutlined, KeyOutlined } from "@ant-design/icons";
import routerProvider from "@pankod/refine-react-router-v6";
import { config } from "config";
const { Link } = routerProvider;

export default function ResetPassword() {
  const { push } = useNavigation();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") || "";
  const decoded = token && (jwtDecode(token) as any);
  const now = new Date();
  const isExpired = decoded && decoded.exp * 1000 < now.getTime();
  const { mutateAsync: resetPassword, isLoading } = useCustomMutation();
  const { isLoading: isGetUserLoading, data } = useOne({
    resource: "users",
    id: decoded?.sub,
    queryOptions: {
      enabled: Boolean(decoded?.sub),
    },
  });
  const user = data?.data;
  const handleSubmit = async (e: any) => {
    const res = await resetPassword({
      url: config.API_URL + "/auth/resetPassword",
      values: {
        email: user?.email,
        password: e.password,
      },
      method: "post",
      successNotification: {
        type: "success",
        message: "Your password has been reset successfully",
      },
    });
    if (res.data) {
      localStorage.removeItem(config.TOKEN_KEY);
      localStorage.removeItem(config.USER_KEY);
      push("/login");
    }
  };

  return (
    <div className={"login-form pageload"}>
      <Form
        className="w-full max-w-xl px-10 py-12"
        onFinish={(values) => {
          handleSubmit(values);
        }}
      >
        <img src={logo} alt="Logo" className={"logo"} />
        {(isExpired && token) || (!token && <p className="mb-10">Your token is expired. Please try again</p>)}
        {token && !isExpired && (
          <>
            <span className="key">
              <KeyOutlined style={{ fontSize: 40, color: "#8b2e2d" }} />
            </span>
            <h1 className={"title"}>Reset your password</h1>
            <InputBorderBottomWrapper>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                    min: 6,
                  },
                ]}
              >
                <InputPrimary className={"input"} type="password" placeholder="Enter a new password" />
              </Form.Item>
            </InputBorderBottomWrapper>
            <InputBorderBottomWrapper>
              <Form.Item
                name="passwordConfirm"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <InputPrimary className={"input"} type="password" placeholder="Confirm your new password" />
              </Form.Item>
            </InputBorderBottomWrapper>{" "}
            <PasswordButton isLoading={isLoading || isGetUserLoading} type={"string"} />
          </>
        )}

        <div className="back">
          <Link to="/Login" style={{ color: "#00899f" }}>
            <span>
              <ArrowLeftOutlined />
            </span>
            Back to login
          </Link>
        </div>
      </Form>
    </div>
  );
}
