import { CloseCircleFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Form, FormInstance } from "@pankod/refine-antd";
import { FormListFieldData, Input, InputNumber, Switch } from "antd";
import React from "react";

interface AnswerItemProps {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  form?: FormInstance<{}>;
  data?: any;
}

export const AnswerItem: React.FC<AnswerItemProps> = ({ field, remove, data }) => {
  const [isFreeTyping, setIsFreeTyping] = React.useState(false);
  const onSwitchChange = (checked: boolean) => {
    setIsFreeTyping(checked);
  };
  return (
    <div
      className="border border-gray-300 rounded-sm border-solid p-4 relative mb-4"
      css={css`
        label {
          font-size: 11px !important;
          font-weight: 500;
        }
      `}
    >
      <CloseCircleFilled
        className="absolute right-[10px] top-[10px] text-red-400 text-[20px] cursor-pointer"
        onClick={() => remove(field.key)}
      />
      <Form.Item
        rules={[
          {
            required: true,
            message: "name is required!",
          },
        ]}
        label={`Name`}
        name={[field.name, "name"]}
        className="w-[100px]"
      >
        <Input maxLength={30} className="font-normal" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: !Boolean(isFreeTyping),
            message: "Description is required!",
          },
        ]}
        label={"Description"}
        name={[field.name, "description"]}
      >
        <Input className="font-normal" />
      </Form.Item>
      <Form.Item className="mb-0" label={"Free text"} name={[field.name, "extra"]} valuePropName="checked">
        <Switch onChange={onSwitchChange} />
      </Form.Item>
      {data && (
        <div className="mt-5">
          <p className="text-[11px] font-semibold">Dimension</p>
          <Form.List name={[field.name, "hra_scoring"]}>
            {(fields, { add, remove }, { errors }) => (
              <div className="flex space-x-2">
                {data?.map((item: any) => {
                  return (
                    <div key={item.id} className="w-[150px]">
                      <Form.Item
                        rules={[
                          {
                            type: "number",
                          },
                        ]}
                        label={item.name}
                        name={[item.id, "score"]}
                      >
                        <InputNumber min={-10} max={10} type={"number"} className="font-normal" />
                      </Form.Item>
                    </div>
                  );
                })}
                <p className="flex justify-end h-[22px] text-red-700">{errors}</p>
              </div>
            )}
          </Form.List>
        </div>
      )}
    </div>
  );
};
