import { useForm, Form, Input, InputNumber, Select, useSelect, Edit } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { useMe } from "context/MeContext";
import React from "react";

export const LocationEdit: React.FC = () => {
  const { push } = useNavigation();
  const { company } = useMe();
  const { id } = useParams();
  const { formProps, saveButtonProps } = useForm({
    resource: "location",
    redirect: false,
    id,
    onMutationSuccess: () => {
      push("/admin/metadata/location");
    },
    metaData: {
      join: {
        field: "company",
      },
    },
  });
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: company.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company.id),
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          className="w-100"
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Company"}
          name={["company", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
          className="w-full"
        >
          <Select {...selectProps} placeholder="Company" disabled />
        </Form.Item>
        <Form.Item className="w-100" label={"Order"} name="order">
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );
};
