import { useForm, Form, Input, Edit, Row, Col } from "@pankod/refine-antd";
import { useParams } from "@pankod/refine-react-router-v6";
import React from "react";
export const DimensionEdit: React.FC = () => {
  const { id } = useParams();
  const { formProps, saveButtonProps } = useForm({
    resource: "dimension",
    id,
    redirect: false,
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <Form.Item
              label={"Name"}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Order"}
              name="order"
              rules={[
                {
                  required: true,
                  min: 0,
                },
              ]}
            >
              <Input min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
