import { css } from "@emotion/react";
import { Input, InputProps } from "@pankod/refine-antd";
import { InputRef } from "antd";
import React, { PropsWithChildren } from "react";
import tw from "twin.macro";

export const InputBorderBottomWrapper: React.FC<PropsWithChildren<{ className?: string }>> = ({
  className = "",
  children,
}) => {
  return (
    <div
      className={`${className}`}
      css={css`
        ${tw`flex items-center w-full`}
        .ant-input-group-wrapper {
          padding: 0 !important;
        }
        div {
          ${tw` w-full bg-transparent! border-0 px-0`}
          input[type="search"] {
            margin-top: 0 !important;
          }
          input,
          .ant-select-selector,
          .ant-picker-suffix {
            margin-top: 0.5rem !important;
            ${tw`placeholder:text-[#00899f]/[0.6]! text-[#00899f]`}
          }
          .ant-select-selector {
            height: 30px !important;
            ${tw`border-0! border-transparent! focus:border-none shadow-none! focus:outline-none! `}
            .ant-select-selection-placeholder,
            .ant-select-selection-item {
              display: flex;
              ${tw`text-[#00899f]/[0.6]! font-normal`}
            }
            .ant-select-selection-search {
              width: 100%;
              inset-inline-start: 0px;
              inset-inline-end: 0px;
              height: 30px;
            }
          }
          .ant-select-selection-placeholder {
            ${tw`text-[#00899f]!`}
          }
          .ant-picker-clear {
            margin-top: 0.5rem;
            background: transparent;
            right: 1rem;
          }
          .ant-select-arrow {
            top: 60%;
          }
          .ant-form-item-control-input-content {
            ${tw`border-b! py-2 text-[#00899f]/[0.5]! rounded-none! border-solid	`}
          }
        }

        .ant-input-wrapper {
          display: flex;
          .ant-input-group-addon {
            border: 0 !important;
            display: flex;
            width: auto;
            margin-top: 0.5rem;
            padding: 0.25rem;
            ${tw`bg-primary/[0.2] text-[#00899f]`}
          }
          .ant-input {
            ${tw`appearance-none w-full bg-transparent! border-0! focus:border-none shadow-none! py-0 focus:outline-none!  px-0 outline-none!`}
          }
        }
      `}
    >
      {children}
    </div>
  );
}; 

export const InputPrimary: React.FC<InputProps & React.RefAttributes<InputRef>> = (props) => {
  return (
    <Input
      css={css`
        ${tw`appearance-none w-full bg-transparent! border-0! focus:border-none shadow-none! py-1 focus:outline-none!  px-0 outline-none!`}
      `}
      {...props}
    />
  );
};
