import React, { useEffect, useState, useRef } from "react";
import { css, Global } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { Badge, Input, AutoComplete, Typography } from "antd";
import { useCompany } from "context/CompanyContext";
import { PropsWithChildren } from "react";
import { Link } from "@pankod/refine-react-router-v6";
import { IOptions } from "types/search";
import { useMe } from "context/MeContext";
import { useApiUrl, useCustomMutation, useList, useNavigation, useSubscription, useUpdate } from "@pankod/refine-core";
import { useDebounceValue } from "usehooks-ts";
import "./forum-layout.scoped.scss";
import dayjs from "dayjs";
import { uniqBy } from "lodash-es";
import { formatDistanceToNow } from "date-fns";
interface ReplyNotificationProps {
  replyTimestamp: string;
}

library.add(fas, faReply);
const { Search } = Input;
const { Text } = Typography;
const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>
      {title}
    </Text>
  );
};

const renderItem = (title: string, resource: string, id: number) => {
  return {
    value: title,
    label: (
      <Link to={`/${resource}/${id}`} className="w-full block">
        <Text className="w-full">{title}</Text>
      </Link>
    ),
  };
};

export const ForumLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { push } = useNavigation();
  const { hra_summary, id, path } = useCompany();
  const { me } = useMe();
  const [debouncedValue, setValue] = useDebounceValue("", 500);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [currentPageMessage, setCurrentPageMessage] = useState<number>(1);
  //console.log({ currentPageMessage });
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { refetch: refetchPosts } = useList({
    resource: "post",
    config: {
      filters: [
        {
          field: "topic.category.company_id",
          operator: "eq",
          value: id,
        },
        {
          operator: "or",
          value: [
            {
              field: "content_raw",
              operator: "contains",
              value: debouncedValue,
            },
            {
              field: "name",
              operator: "contains",
              value: debouncedValue,
            },
          ],
        },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const postOptionGroup = data.data.map((item) =>
          renderItem(item.name, `${path}/forums/topic/${item.topic.id}/post`, item.id as number),
        );
        if (postOptionGroup.length > 0) {
          setOptions([
            {
              label: renderTitle("Posts"),
              options: postOptionGroup,
            },
          ]);
        } else {
          setOptions([]);
        }
      },
    },
    metaData: {
      join: [
        {
          field: "topic",
        },
        {
          field: "topic.category",
        },
      ],
    },
  });
  const { refetch: refetchMessageNoti } = useList({
    resource: "message",
    config: {
      filters: [
        {
          operator: "or",
          value: [
            {
              operator: "and",
              value: [
                {
                  field: "post.user_id",
                  operator: "eq",
                  value: me?.id,
                },
                {
                  field: "user_id",
                  operator: "ne",
                  value: me?.id,
                },
              ],
            },
            {
              operator: "and",
              value: [
                {
                  field: "replyToMessage.user_id",
                  operator: "eq",
                  value: me?.id,
                },
              ],
            },
          ],
        },
      ],
      pagination: {
        pageSize: 10,
        current: currentPageMessage || 1,
      },
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    queryOptions: {
      onSuccess: (data) => {
        setMessages((prev) => {
          const _arr = uniqBy([...(data.data || []), ...prev], "id");
          return _arr;
        });
      },
    },
    metaData: {
      join: [
        {
          field: "post",
        },
        {
          field: "user",
        },
      ],
    },
  });

  const { data: dataMessagesCount, refetch: refetchMessageCount } = useList({
    resource: "message",
    config: {
      filters: [
        {
          operator: "or",
          value: [
            {
              operator: "and",
              value: [
                {
                  field: "post.user_id",
                  operator: "eq",
                  value: me?.id,
                },
                {
                  field: "seenAt",
                  operator: "null",
                  value: true,
                },
                {
                  field: "user_id",
                  operator: "ne",
                  value: me?.id,
                },
              ],
            },
            {
              operator: "and",
              value: [
                {
                  field: "replyToMessage.user_id",
                  operator: "eq",
                  value: me?.id,
                },
                {
                  field: "seenAt",
                  operator: "null",
                  value: true,
                },
              ],
            },
          ],
        },
      ],
      pagination: {
        pageSize: 1,
        current: 1,
      },
    },
    metaData: {
      join: [
        {
          field: "post",
        },
      ],
    },
  });
  const unSeenMessagesTotal = dataMessagesCount?.total || 0;
  const unSeenMessages = messages;
  //console.log({ messages });
  useSubscription({
    channel: "resources/message",
    types: ["*"],
    onLiveEvent: () => {
      refetchMessageCount();
      refetchMessageNoti();
    },
    params: {
      filters: [
        {
          field: "post.user_id",
          operator: "eq",
          value: me?.id,
        },
      ],
    },
  });

  useEffect(() => {
    if (debouncedValue) {
      refetchPosts();
    } else {
      setOptions([]);
    }
  }, [debouncedValue, refetchPosts]);

  // to find pagination of message.
  const apiUrl = useApiUrl();
  const { mutateAsync: findPageById } = useCustomMutation();

  const { mutateAsync: updateMessage } = useUpdate();

  const markAsSeen = async (id: string, postId: string, topicId: string) => {
    await updateMessage({
      resource: "message",
      values: {
        seenAt: dayjs(),
      },
      id,
      successNotification: false,
    });
    const { data } = await findPageById({
      url: `${apiUrl}/message/count/${id}`,
      method: "patch",
      values: {
        topicId,
        postId,
      },
    });
    if (data.page) {
      push(`/${path}/forums/topic/${topicId}/post/${postId}?page=${data.page}#${id}`);
    }
  };
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: rgba(235, 234, 235, 0.5);
          }
        `}
      />
      <div
        className="forums-page pageload"
        css={css`
          th {
            background-color: ${hra_summary?.background_color};
            color: ${hra_summary?.main_hra_color};
          }
          .left-column {
            color: #333;
          }
          .last-column {
            color: #333;
          }
          .last-column {
            span {
              //color: #8b8e90;
              .user-icon {
                font-size: 10px;
                margin-bottom: 2px;
              }
            }
          }
          .icon {
            font-size: 22px !important;
            font-weight: 100 !important;
            position: absolute;
            top: 27px;
          }
          .text {
            color: ${hra_summary?.main_hra_color} !important;
            font-weight: 550;
          }
          .line {
            width: 100%;
            border: 1px solid ${hra_summary?.main_hra_color};
            display: inline-block;
            margin: 20px 0 0 0;
          }
          .table-wrapper {
            border: 1px solid rgba(128, 128, 128, 0.3);
          }
        `}
      >
        <div className="container ">
          <div
            className="info-group"
            css={css`
              display: flex;
              justify-content: flex-end;
              .bell-icon {
                align-items: center !important;
                font-size: 18px;
                border-radius: 50%;
                padding: 5px 7px;
                background-color: ${hra_summary?.main_hra_color};
                color: white !important;
              }
              .noti-icon {
                font-size: 50px;
                color: #b1b1b1;
                padding-bottom: 20px;
              }
              .search {
                margin-left: 20px;
                width: 300px;
              }
            `}
          >
            <div className="notification cursor-pointer">
              <div ref={buttonRef} onClick={() => setIsVisible((prev) => !prev)}>
                <Badge count={unSeenMessagesTotal}>
                  <FontAwesomeIcon icon={["fas", "bell"]} className="bell-icon" />
                </Badge>
              </div>

              {/* <div ref={notificationRef} className={`noti-system ${isVisible ? "block" : "hidden"}`}>
                <div className="noti-content max-h-[500px] overflow-auto">
                  {unSeenMessages?.map((noti) => {
                    const replyDate = new Date(noti?.createdAt);
                    const timeAgo = formatDistanceToNow(replyDate, { addSuffix: true });
                    const isReplyMessage = noti?.replyToMessage?.user_id === me?.id;
                    return (
                      <div key={noti.id}>
                        <div
                          onClick={() => {
                            markAsSeen(noti.id as string, noti.post.id, noti.post.topic_id);
                          }}
                          className={`noti-single cursor-pointer ${noti?.seenAt === null ? "unseen" : "seen"}`}
                        >
                          <span className="reply-icon">
                            <FontAwesomeIcon icon={faReply} />
                          </span>
                          <span className="noti-text">
                            <span style={{ fontWeight: 600 }}>{noti?.user?.email}</span> replied to your{" "}
                            {isReplyMessage ? "message" : "post"}
                          </span>
                          <div>{timeAgo}</div>
                        </div>
                        <div className="line-thin"></div>
                      </div>
                    );
                  })}
                </div>
                <div className="see-more cursor-pointer" onClick={() => setCurrentPageMessage((prev) => prev + 1)}>
                  <span>See more notifications</span>
                </div>
              </div> */}
            {messages?.length === 0 ?
              (<div ref={notificationRef} className={`noti-system ${isVisible ? "block" : "hidden"} no-noti`}>
                <FontAwesomeIcon icon={["fas", "bell"]} className="noti-icon" />
                <h3>No Notifications Yet</h3>
                <p style={{color:"grey"}}>When you get notifications, they'll show up here</p>
              </div>)
              : (
              <div ref={notificationRef} className={`noti-system ${isVisible ? "block" : "hidden"}`}>
                <div className="noti-content">
                  {unSeenMessages?.map((noti) => {
                    const replyDate = new Date(noti?.createdAt);
                    const timeAgo = formatDistanceToNow(replyDate, {addSuffix:true});
                    return (
                    <div key={noti.id}>
                      <div
                        onClick={() => {
                          markAsSeen(noti.id as string, noti.post.id, noti.post.topic_id);
                        }}
                        className={`noti-single cursor-pointer ${noti?.seenAt === null ? "unseen" : "seen"}`}
                      >
                        <span className="reply-icon">
                          <FontAwesomeIcon icon={faReply} />
                        </span>
                        <span className="noti-text"><span style={{fontWeight:600}}>{noti?.user?.email}</span> replied to your post</span>
                        <div>{timeAgo}</div>
                      </div>
                      <div className="line-thin"></div>
                    </div>
                  )})}
                </div>
                <div className="see-more cursor-pointer" onClick={() => setCurrentPageMessage((prev) => prev + 1)}>
                  <span>See more notifications</span>
                </div>
              </div>              
              )}
            </div>
            <div className="search">
              <AutoComplete
                style={{ width: "100%", maxWidth: "550px" }}
                filterOption={false}
                options={options}
                onSearch={(value: string) => setValue(value)}
              >
                <Search placeholder="search" allowClear />
              </AutoComplete>
            </div>
          </div>

          <div className="line"></div>
          <div
            className="main-content"
            css={css`
            // h2 {
            //   color: ${hra_summary?.title_color};
            //   font-size: 40px;
            //   font-weight: 600;
            //   margin: auto 0 auto 15px;
            // }
            .post-button {
              background-color: ${hra_summary?.title_color};
              color: white;
              border: none;
              width: 250px;
              height: auto !important;
              padding: 8px;
              font-size: 17px;
              border-radius: 10px !important;
              &:hover {
                background-color: ${hra_summary?.title_color} !important;
                color: white !important;
                border: none !important;
                opacity: 70% !important;
              }
              .pen-icon
            }
          `}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
