import { useForm, Form, Input, Row, Col, Create, Select, InputNumber, Checkbox } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { UploadImage } from "components/Upload/Upload";
import { ColorPicker, Switch } from "antd";
import { textAlignVerticalOps, textAlignmentHorizontalOps } from "config";
import React, { useState } from "react";
import { chartColorOptions } from "pages/Dashboard/ChartColor";
import { Tooltip } from "antd";
import ReactQuill from "react-quill";
import toolbarOptions from "components/TextEditor/toolbarOptions";
const h4Style = {
  color: "#a10606",
  textDecoration: "underline",
};
const h2Style = {
  color: "#a10606",
};

export const CompanyCreate: React.FC = () => {
  const { push } = useNavigation();
  const { formProps, saveButtonProps, formLoading, form } = useForm({
    resource: "company",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/company");
    },
  });
  const [loading, setLoading] = useState(false);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(e: any) => {
          const position = `${e.banner_alignment_vertical}-${e.banner_alignment_horizontal}`;
          e.portfolio.banner.position = position;
          formProps.onFinish?.(e);
        }}
        initialValues={{
          portfolio: {
            hra_summary: {
              chart_color_scheme: "dark2",
              company_chart_status: true,
            },
            risk_areas: {
              chart_color_scheme: "dark2",
            },
          },
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Name"} name="name">
              <Input />
            </Form.Item>
            <Form.Item label={"Employer Code"} name="code">
              <Input />
            </Form.Item>
            <Form.Item label={"Primary Color"} name={["portfolio", "brand", "color_primary"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "brand", "color_primary"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>

            <Form.Item
              label={"URL Path"}
              name={"path"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={"Website"} name={["portfolio", "contact", "website"]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>HOMEPAGE</h2>
        <Col xs={24} lg={11}>
          <Form.Item label={"Challenges Icon"} name={["portfolio", "homepage", "challenges_icon"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "challenges_icon"]}
            />
          </Form.Item>
          <Form.Item label={"Activities Icon"} name={["portfolio", "homepage", "activities_icon"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "activities_icon"]}
            />
          </Form.Item>
          <Form.Item label={"Resources Icon"} name={["portfolio", "homepage", "resources_icon"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "resources_icon"]}
            />
          </Form.Item>
          <Form.Item label={"HRA Icon"} name={["portfolio", "homepage", "hra_icon"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "hra_icon"]}
            />
          </Form.Item>
          <Form.Item label={"Contact Icon"} name={["portfolio", "homepage", "contact_icon"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "contact_icon"]}
            />
          </Form.Item>
          <Form.Item label={"Home Background"} name={["portfolio", "homepage", "home_bg"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "homepage", "home_bg"]}
            />
          </Form.Item>
          <Form.Item name={["portfolio", "homepage", "home_title"]} label="Home Title">
            <Input />
          </Form.Item>
          <Form.Item name={["portfolio", "homepage", "home_description"]} label="Home Description">
            {/* <Input.TextArea /> */}
            <ReactQuill theme="snow" modules={{ toolbar: toolbarOptions }} />
          </Form.Item>
          <Form.Item
            name={["portfolio", "homepage", "nav_blur"]}
            valuePropName="checked"
            label="Blur effect behind navbar"
          >
            <Switch />
          </Form.Item>
          <Form.Item name={["portfolio", "homepage", "chat_icon"]} label={"Chat Icon"}>
            <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "homepage", "chat_icon"]}
            />
          </Form.Item>
        </Col>
        <hr></hr>
        {/* ============================================================================== */}
        {/* ============================================================================== */}
        <h2 style={h2Style}>HEADER</h2>
        <Col xs={24} lg={11}>
          <Form.Item label={"Logo (landscape - 3:1 ratio)"} name={["portfolio", "brand", "logo"]}>
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "brand", "logo"]}
            />
          </Form.Item>
          <Form.Item name={["portfolio", "brand", "border_radius"]} valuePropName="checked" label="Border-radius?">
            <Checkbox />
          </Form.Item>
          <Form.Item label={"Header background color"} name={["portfolio", "brand", "background_color"]}>
            <ColorPicker
              onChange={(_, value) => {
                form.setFieldValue(["portfolio", "brand", "background_color"], value);
              }}
              showText
              format="hex"
            />
          </Form.Item>
          <Form.Item label={"Navbar Font Size"} name={["portfolio", "brand", "navbar_fontsize"]}>
            <InputNumber />
          </Form.Item>

          <Form.Item label={"Navbar color"} name={["portfolio", "brand", "navbar_color"]}>
            <ColorPicker
              onChange={(_, value) => {
                form.setFieldValue(["portfolio", "brand", "navbar_color"], value);
              }}
              showText
              format="hex"
            />
          </Form.Item>
        </Col>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>FOOTER</h2>
        <Col xs={24} lg={11}>
          <Form.Item
            label={"Footer background image (landscape 1600x680)"}
            name={["portfolio", "footer", "background_url"]}
          >
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "footer", "background_url"]}
            />
          </Form.Item>
          <Form.Item label={"Footer credit background color"} name={["portfolio", "footer", "background_color"]}>
            <ColorPicker
              onChange={(_, value) => {
                form.setFieldValue(["portfolio", "footer", "background_color"], value);
              }}
              showText
              format="hex"
            />
          </Form.Item>
          <Form.Item
            name={["portfolio", "footer", "responsiveFooter"]}
            valuePropName="checked"
            label={"Responsive footer"}
          >
            <UploadImage
              setLoading={setLoading}
              loading={loading || formLoading}
              form={form}
              name={["portfolio", "footer", "responsiveFooter"]}
            />
          </Form.Item>
          {/* <Form.Item label={"Footer credit background color"} name={["portfolio", "footer", "copyright_bg"]}>
            <Input />
          </Form.Item> */}
        </Col>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>CONTACT PAGE</h2>
        <Row gutter={[64, 11]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item
              label={`Description (eg: "District 51 - All benefit questions")`}
              name={["portfolio", "contact", "title"]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={"Phone Number (format: (0AA) NXX-XXXX)"} name={["portfolio", "contact", "phone"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Email"} name={["portfolio", "contact", "email"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Address"} name={["portfolio", "contact", "address"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"MH Title"} name={["portfolio", "contact", "mh_title"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Company Color"} name={["portfolio", "contact", "company_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "contact", "company_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Show MH"} name={["portfolio", "contact", "show_mh"]} valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        {/* <h2 style={h2Style}>RESOURCES PAGE</h2>
        <p>...</p>
        <hr></hr> */}
        {/* ============================================================================== */}
        {/* ============================================================================== */}
        <h2 style={h2Style}>HRA PAGE</h2>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Employer Name"} name={["portfolio", "hra", "employer_name"]}>
              <Input />
            </Form.Item>

            <Form.Item label={"Horizontal image"} name={["portfolio", "hra", "image1"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "hra", "image1"]}
              />
            </Form.Item>
            <Form.Item label={"Vertical image"} name={["portfolio", "hra", "image2"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "hra", "image2"]}
              />
            </Form.Item>
            <Form.Item name={["portfolio", "hra", "can_skip_hra"]} valuePropName="checked" label={"Can Skip HRA?"}>
              <Checkbox />
            </Form.Item>
            <Form.Item label={"Text color"} name={["portfolio", "hra_summary", "main_hra_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "hra_summary", "main_hra_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Background color of text boxes"} name={["portfolio", "hra_summary", "background_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "hra_summary", "background_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={"Uncomplete HRA button color"}
              name={["portfolio", "hra_summary", "uncomplete_button_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "hra_summary", "uncomplete_button_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>HOME PAGE</h2>
        <h4 style={h4Style}>BANNER</h4>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Banner image"} name={["portfolio", "banner", "banner_url"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "banner", "banner_url"]}
              />
            </Form.Item>

            <Form.Item
              className="mt-3"
              labelAlign="left"
              label="Title & Tagline positioning (vertical)"
              name={"banner_alignment_vertical"}
            >
              <Select size="large" options={textAlignVerticalOps} placeholder="Alignment" />
            </Form.Item>
            <Form.Item
              className="mt-3"
              labelAlign="left"
              label="Title & Tagline positioning (horizontal)"
              name={"banner_alignment_horizontal"}
            >
              <Select size="large" options={textAlignmentHorizontalOps} placeholder="Alignment" />
            </Form.Item>

            <Form.Item
              name={["portfolio", "banner", "responsiveBanner"]}
              valuePropName="checked"
              label={"Responsive banner"}
            >
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "banner", "responsiveBanner"]}
              />
            </Form.Item>

            <Form.Item label={"Employer name"} name={["portfolio", "employer", "name"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Employer name - color"} name={["portfolio", "employer", "color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "employer", "color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Employer name - font-size"} name={["portfolio", "employer", "font_size"]}>
              <InputNumber />
            </Form.Item>
            <Form.Item label={"Tagline"} name={["portfolio", "employer", "tagline"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Tagline - color"} name={["portfolio", "employer", "tagline_color"]}>
              <ColorPicker
                defaultValue="black"
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "employer", "tagline_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Tagline - font-size"} name={["portfolio", "employer", "tagline_font_size"]}>
              <InputNumber />
            </Form.Item>
            <Form.Item label={'"Contact Us" button - color'} name={["portfolio", "employer", "btn_contact_us_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "employer", "btn_contact_us_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>YOUR HEALTH RISK REPORT PAGE</h2>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Titles - color"} name={["portfolio", "hra_summary", "title_color"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Chart - color scheme"} name={["portfolio", "hra_summary", "chart_color_scheme"]}>
              <Select style={{ width: 120 }} options={chartColorOptions} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Show Average Columns
                  <Tooltip title="If you turn this off, the HRA charts will hide the average columns by default">
                    <div style={{ fontSize: 11, display: "inline-block", marginLeft: 8 }}>ⓘ</div>
                  </Tooltip>
                </span>
              }
              name={["portfolio", "hra_summary", "company_chart_status"]}
              className="w-[200px]"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  default_chart_threshold
                  <Tooltip title="If you turn this off, the HRA charts will hide the average columns by default">
                    <div style={{ fontSize: 11, display: "inline-block", marginLeft: 8 }}>ⓘ</div>
                  </Tooltip>
                </span>
              }
              name={["portfolio", "hra_summary", "default_chart_threshold"]}
              className="w-[200px]"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  user_threshold
                  <Tooltip title="If you turn this off, the HRA charts will hide the average columns by default">
                    <div style={{ fontSize: 11, display: "inline-block", marginLeft: 8 }}>ⓘ</div>
                  </Tooltip>
                </span>
              }
              name={["portfolio", "hra_summary", "user_threshold"]}
              className="w-[200px]"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        {/* ============================================================================== */}
        <h4 style={h4Style}>RISK AREAS</h4>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item
              label={"Color of the bar on the chart when results > 0"}
              name={["portfolio", "risk_areas", "title_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "risk_areas", "title_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={"Color of the bar on the chart when results < 0"}
              name={["portfolio", "risk_areas", "title_color2"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "risk_areas", "title_color2"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            {/* <Form.Item
              label={"Chart - color scheme"}
              name={["portfolio", "risk_areas", "chart_color_scheme"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select style={{ width: 120 }} options={chartColorOptions} />
            </Form.Item> */}
            <Form.Item label={"Image (portrait)"} name={["portfolio", "risk_areas", "image_url"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "risk_areas", "image_url"]}
              />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>ACTIVITIES PAGE</h2>
        <h4 style={h4Style}>Activities</h4>
        <h4 style={h4Style}>ACTION PLAN PROGRESS</h4>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Title - color"} name={["portfolio", "action_plan", "title_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "title_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Box - border color"} name={["portfolio", "action_plan", "border_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "border_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={"Level & Circular progress bar - background color"}
              name={["portfolio", "action_plan", "background_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "background_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Level - text color"} name={["portfolio", "action_plan", "level_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "level_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={'"ADD ACTIVITY" button - background color'}
              name={["portfolio", "action_plan", "btn_add_activity_background_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "btn_add_activity_background_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={"FIND YOUR ACTIVITIES - title color & activity background color"}
              name={["portfolio", "action_plan", "find_your_activities_background_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "action_plan", "find_your_activities_background_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
          </Col>
        </Row>
        {/* ============================================================================== */}
        <h4 style={h4Style}>LEADERBOARD</h4>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"“Level” & “Total Trophies” - color"} name={["portfolio", "leaderboard", "level_color"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "leaderboard", "level_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item
              label={"Level & Total Trophies of user - color"}
              name={["portfolio", "leaderboard", "other_texts_color"]}
            >
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "leaderboard", "other_texts_color"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Image"} name={["portfolio", "leaderboard", "image_url"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "leaderboard", "image_url"]}
              />
            </Form.Item>
          </Col>
        </Row>
        <hr></hr>
        {/* ============================================================================== */}
        <h2 style={h2Style}>CHALLENGE PAGE</h2>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Month"} name={["portfolio", "challenge", "month"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Season"} name={["portfolio", "challenge", "season"]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Banner image"} name={["portfolio", "challenge", "banner_image"]}>
              <UploadImage
                setLoading={setLoading}
                loading={loading || formLoading}
                form={form}
                name={["portfolio", "challenge", "banner_image"]}
              />
            </Form.Item>
            <Form.Item label={"Tagline text color"} name={["portfolio", "challenge", "text_color1"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "challenge", "text_color1"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
            <Form.Item label={"Season text color"} name={["portfolio", "challenge", "text_color2"]}>
              <ColorPicker
                onChange={(_, value) => {
                  form.setFieldValue(["portfolio", "challenge", "text_color2"], value);
                }}
                showText
                format="hex"
              />
            </Form.Item>
          </Col>
        </Row>
        {/* ============================================================================== */}
      </Form>
    </Create>
  );
};
