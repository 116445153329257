import React, {useEffect} from 'react';
import emailjs from '@emailjs/browser';
import { useCompany } from 'context/CompanyContext';
import "./report.scoped.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFlag} from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far, faFlag);

const ReportButton = ({ postId, commentId, topicId, pageId, userId, commentContent, }) => {
  const {path: company_path} = useCompany();
  const REACT_APP_EMAIL_USERID_CHAT = process.env.REACT_APP_EMAIL_USERID_CHAT;
  useEffect(() => {
    if (REACT_APP_EMAIL_USERID_CHAT) {
      emailjs.init(REACT_APP_EMAIL_USERID_CHAT);
    } else {
      console.error('EmailJS user ID is not defined');
    }
  }, [REACT_APP_EMAIL_USERID_CHAT]);
  const handleReport = () => {
    const queryParams = pageId || commentId
    ? `?page=${pageId || ''}#${commentId || ''}`
    : '';
    const emailContent = {
      commentId: commentId,
      userId: userId,
      postId: postId,
      pageId: pageId,
      dateTime: new Date().toLocaleString(),
      commentContent: commentContent.slice(0, 200), 
      commentLink: `http://mhgood.life/${company_path}/forums/topic/${topicId}/post/${postId}${queryParams}`
    };

    // Send the email using EmailJS
    emailjs.send('service_yy4lzs3', 'template_l0rg5hi', emailContent, REACT_APP_EMAIL_USERID_CHAT )
      .then((result) => {
        console.log('Email sent:', result.text);
        alert('Thank you for your report. Our team will review this reply and take appropriate action. Your feedback helps us keep the forum a safe and respectful place for everyone!');
      })
      .catch((error) => {
        console.log('Error sending email:', error.text);
        alert('Failed to send report.');
      });
  };

  return (
    <button className='report-button' onClick={handleReport}> <FontAwesomeIcon icon="fa-regular fa-flag" className='fa-flag'/> Report</button>
  );
};

export default ReportButton;
