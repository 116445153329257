import { AuthProvider, HttpError } from "@pankod/refine-core";
import { config } from "../config";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      description: "asdsa",
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

export const authProviderRest: AuthProvider = {
  login: () => Promise.resolve(),
  logout: async () => {
    const tokensRaw = localStorage.getItem(config.TOKEN_KEY);
    const tokens = tokensRaw ? JSON.parse(tokensRaw) : undefined;
    const res = await axios.get(`${config.API_URL}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    });
    if (res.status === 200) {
      localStorage.removeItem(config.TOKEN_KEY);
      localStorage.removeItem(config.USER_KEY);
    }

    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const tokensRaw = localStorage.getItem(config.TOKEN_KEY);
    const tokens = tokensRaw ? JSON.parse(tokensRaw) : undefined;
    let access_token = tokens?.accessToken;
    if (access_token && tokens) {
      const decoded = jwt_decode(access_token) as any;
      const now = new Date().getTime();
      const expTime = decoded.exp * 1000;
      const isExpired = now - expTime > 0;
      if (isExpired) {
        const res = await axios.get(`${config.API_URL}/auth/refresh`, {
          headers: {
            Authorization: `Bearer ${tokens.refreshToken}`,
          },
        });
        const { tokens: _tokens, user: _user } = res.data;
        if (res.data) {
          access_token = _tokens.access_token;
          _tokens && localStorage.setItem(config.TOKEN_KEY, JSON.stringify(_tokens));
          _user && localStorage.setItem(config.USER_KEY, JSON.stringify(_user));
        }
      }
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    return Promise.reject();
  },
};
