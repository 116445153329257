import { Input } from "antd";
import { UploadFiles } from "components/Upload/UploadFile";
import { DataType } from "types/challenge";
import { StatTypeChildProps } from "../StatType";
import "../updateStats.scoped.scss";

export const FreeTextType: React.FC<StatTypeChildProps> = ({
  data,
  userResult,
  setUserResult,
  week,
  onUpdateChild,
  order,
  disabled,
  files,
  onRemoveFile,
  setLoading,
  loading,
  callback,
}) => {
  const answer = userResult?.[week]?.[order]?.user_answer;
  const onUpdate = async (user_answer: string) => {
    const getValue = (hasAnswer: boolean) => {
      const no_answer = data?.results[DataType.FreeText]?.[0]?.no_answer;
      const answer = data?.results[DataType.FreeText]?.[0]?.answer;
      return hasAnswer ? answer : no_answer;
    };
    const _child = onUpdateChild?.(getValue(Boolean(user_answer)), user_answer, data);
    const _userResult = {
      ...userResult,
      [week]: _child,
    };
    setUserResult(_userResult);
  };

  return (
    <div className="single-activity">
      <hr className="single-activity-hr" />
      <div className="j-between single-activity-group ">
        <div className="show-on-responsive one">
          <div className="left-col col">{data.title}</div>
          <div className="center-col col">{data.description}</div>
        </div>
        <div className="left-col col show-on-lg">{data.title}</div>
        <div className="center-col col show-on-lg">{data.description}</div>
        <div className="right-col j-between col buttons two">
          <Input
            onChange={(e) => onUpdate(e.target.value)}
            value={answer}
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </div>
        <div className="right-right-col col upload-col three">
          <UploadFiles
            key={files[files.length - 1]}
            isDisabled={disabled}
            loading={loading}
            setLoading={setLoading}
            callback={callback}
            onRemoveFile={onRemoveFile}
            files={files}
          />
        </div>
      </div>
    </div>
  );
};
