import { PlusOutlined } from "@ant-design/icons";
import { Form, FormInstance } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import React from "react";
import { AnswerItem } from "./AnswerItem";

export const AnswerListComponent: React.FC<{ form?: FormInstance<{}> }> = ({ form }) => {
  const { data: dimensionResult } = useList({
    resource: "dimension",
    config: {
      sort: [
        {
          field: "order",
          order: "asc",
        },
      ],
    },
  });
  const data = dimensionResult?.data;
  const a = form?.getFieldValue("answer_detail");
  return (
    <div className="border bg-gray-100 p-4 mt-4">
      <h3>Answer List</h3>
      <Form.List name={"answer_detail"}>
        {(fields, { add, remove }, { errors }) => (
          <div>
            {fields.map((field) => {
              return <AnswerItem key={field.name} field={field} remove={remove} form={form} data={data} />;
            })}
            <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

            {fields.length < 10 && (
              <div onClick={() => add()} className="cursor-pointer flex justify-end">
                <PlusOutlined className="mr-2" />
                Add more
              </div>
            )}
          </div>
        )}
      </Form.List>
    </div>
  );
};
