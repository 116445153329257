import React, { useRef } from "react";
import "./contact.scoped.scss";
import emailjs from "@emailjs/browser";
import { css } from "@emotion/react";
import { IconPhoneCall, IconEnvelope, IconLocationOutline } from "../../components/Icons/index";
import { theme } from "@pankod/refine-antd";
import { message } from "antd";
import { useCompany } from "context/CompanyContext";

const { useToken } = theme;
const Contact: React.FC = () => {
  const REACT_APP_EMAIL_KEY = process.env.REACT_APP_EMAIL_KEY;
  // cách 1
  const company = useCompany();
  const { contact, brand, hra_summary, path: company_path } = company;
  // cách 2
  // const { contact } = useCompany();
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs.sendForm("service_ddnvx7q", "template_j6kpq1o", e.target, REACT_APP_EMAIL_KEY).then(
      (result) => {
        console.log("result", result.text);
      },
      (error) => {
        console.log("error", error.text);
      },
    );
  };
  const openMessage = (e: any) => {
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Message sent! Thank you for contacting us, we will get back to you as soon as possible.",
        duration: 5,
      });
    }, 1000);
  };

  return (
    <div className="contact-page pageload">
      {contextHolder}
      <div
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <h1
          css={css`
            border-bottom: 1px solid ${brand?.color_primary};
            padding-bottom: 5px;
            width: 50%;
            display: inline-block;
            color: ${brand?.color_primary};
          `}
        >
          Contact Us
        </h1>
      </div>
      <div
        className="contact-page-container container j-between"
        css={css`
          color: ${brand?.color_primary};
          .buton {
            background-color: ${brand?.color_primary};
          }
          p {
            color: ${brand?.color_primary};
          }
          h4 {
            color: ${hra_summary?.title_color};
          }
          @media screen and (max-width: 768px) {
            .contact-column-left {
              background-color: ${brand?.color_primary};
            }
          }
        `}
      >
        <div className="contact-column-left cotact-column" onSubmit={sendEmail} id="contact-form">
          <form
            ref={form}
            onSubmit={sendEmail}
            id="contact-form"
            css={css`
              color: ${brand?.color_primary} !important;
              border-color: ${brand?.color_primary} !important;
              font-size: 20px !important;
            `}
          >
            <h4 className="need-help">Need help ?</h4>
            <div className="main-title-title contact">Send us a message</div>
            <div className="contact-responsive">
              <div className="align-left">
                <h4>{contact?.title}</h4>
                <a href={`tel:${contact?.phone}`}>
                  <p>
                    <IconPhoneCall />
                    <span>{contact?.phone}</span>
                  </p>
                </a>
                <a href={`mailto:${contact?.email}`}>
                  <p>
                    <IconEnvelope />
                    <span>{contact?.email}</span>
                  </p>
                </a>
                <a href={`${contact?.website}`} target="_blank" rel="noreferrer">
                  <p>
                    <IconLocationOutline />
                    <span>{contact?.address}</span>
                  </p>
                </a>
                {company_path !== "marshall" ? (
                  ""
                ) : (
                  <div>
                    <br />
                    <p className="contact-people">Chief Wellness Officer</p>
                    <p className="contact-people">Dr. Leanne Camisa</p>
                    <a href={`mailto:${contact?.email}`}>
                      <p>
                        <IconEnvelope />
                        <span>lcamisa@marshallmedical.org</span>
                      </p>
                    </a>
                    <br />
                    <p className="contact-people">Dr. Leanne Camisa</p>
                    <a href={`mailto:${contact?.email}`}>
                      <p>
                        <IconEnvelope />
                        <span>mfox@marshallmedical.org</span>
                      </p>
                    </a>
                    <a href={`tel:${contact?.phone}`}>
                      <p>
                        <IconPhoneCall />
                        <span>(530) 626-2811</span>
                      </p>
                    </a>
                  </div>
                )}
              </div>
              <br />
              <div className="align-right">
                {company_path === "marshall" ? (
                  <h4>
                    Monument Health - Questions about the wellness platform or technical assistance with the platform
                  </h4>
                ) : (
                  <h4>Monument Health - Questions about medical benefits, finding a provider, in-network options</h4>
                )}
                <a href="tel:9706835690">
                  <p>
                    <IconPhoneCall />
                    <span>(970) 683-5630</span>
                  </p>
                </a>
                <a href="mailto:support@monumenthealth.net">
                  <p>
                    <IconEnvelope />
                    <span>goodlife.support@monumenthealth.net</span>
                  </p>
                </a>
                <a href="https://monumenthealth.net/" target="_blank" rel="noreferrer">
                  <p>
                    <IconLocationOutline />
                    <span>
                      744 Horizon Court #260<div>Grand Junction, CO 81506</div>
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <input type="text" name="name" placeholder="Name" className="form-input" />
            <input type="email" name="email" placeholder="Email" className="form-input" />
            <textarea name="message" placeholder="Message" className="form-input" />
            <input
              type="submit"
              value="Send"
              className="button"
              onClick={openMessage}
              css={css`
                border-color: ${brand?.color_primary} !important;
                background-color: ${brand?.color_primary} !important;
                &:hover {
                  transition: 0.5s ease-in-out !important;
                  background-color: ${hra_summary?.title_color};
                }
              `}
            />
          </form>
        </div>

        <div className="contact-column-right contact-column">
          <div className="main-title-title info">Contact</div>
          <div className="">
            <div className="align-left">
              <h4>{contact?.title}</h4>
              {company_path !== "marshall" ? "" : <p className="contact-people">Jeff Hunter</p>}
              <a href={`tel:${contact?.phone}`}>
                <p>
                  <IconPhoneCall />
                  <span>{contact?.phone}</span>
                </p>
              </a>
              <a href={`mailto:${contact?.email}`}>
                <p>
                  <IconEnvelope />
                  <span>{contact?.email}</span>
                </p>
              </a>
              <a href={`${contact?.website}`} target="_blank" rel="noreferrer">
                <p>
                  <IconLocationOutline />
                  <span>{contact?.address}</span>
                </p>
              </a>
              {company_path !== "marshall" ? (
                ""
              ) : (
                <div>
                  <br />
                  <p className="contact-people">Chief Wellness Officer</p>
                  <p className="contact-people">Dr. Leanne Camisa</p>
                  <a href={`mailto:${contact?.email}`}>
                    <p>
                      <IconEnvelope />
                      <span>lcamisa@marshallmedical.org</span>
                    </p>
                  </a>
                  <br />
                  <p className="contact-people">Marcella Fox</p>
                  <a href={`mailto:${contact?.email}`}>
                    <p>
                      <IconEnvelope />
                      <span>mfox@marshallmedical.org</span>
                    </p>
                  </a>
                  <a href={`tel:${contact?.phone}`}>
                    <p>
                      <IconPhoneCall />
                      <span>(530) 626-2811</span>
                    </p>
                  </a>
                </div>
              )}
            </div>
            <br />
            <div className="align-right">
              {/* {company_path === "marshall" ? <h4>Monument Health - Questions about the wellness platform or technical assistance with the platform</h4> : <h4>Monument Health - Questions about medical benefits, finding a provider, in-network options</h4>} */}
              <h4>{contact?.mh_title}</h4>
              <a href="tel:9706835690">
                <p>
                  <IconPhoneCall />
                  <span>(970) 683-5630</span>
                </p>
              </a>
              <a href="mailto:support@monumenthealth.net">
                <p>
                  <IconEnvelope />
                  <span>goodlife.support@monumenthealth.net</span>
                </p>
              </a>
              <a href="https://monumenthealth.net/" target="_blank" rel="noreferrer">
                <p>
                  <IconLocationOutline />
                  <span>
                    744 Horizon Court #260<div>Grand Junction, CO 81506</div>
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
