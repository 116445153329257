import React, { createContext, PropsWithChildren, useContext } from "react";

// refine
import { ConfigProvider } from "@pankod/refine-antd";

// configs
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import { useCompany } from "../context/CompanyContext";

interface ColorContextProps {
  color: string;
  setColor: (val: string) => void;
}

const ColorContext = createContext<ColorContextProps>({} as ColorContextProps);

export const useColorContext = () => {
  return useContext(ColorContext);
};

export const ColorProvider: React.FC<Omit<PropsWithChildren<ColorContextProps>, "color" | "setColor">> = ({
  children,
  ...props
}) => {
  const { hra_summary,brand } = useCompany();
  const primaryColor = brand?.color_primary;
  const [color, setColor] = useLocalStorage<string>("themeColor", "#d95d01");
  const value = React.useMemo(() => {
    return {
      ...props,
      color,
      setColor,
    };
  }, [color, props, setColor]);
  React.useEffect(() => {
    setColor("#d95d01")
  }, );

  return (
    <>
      <ColorContext.Provider value={value}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: color,
            },
          }}
        >
          {children}
        </ConfigProvider>
      </ColorContext.Provider>
    </>
  );
};