import { useForm, Form, Input, Create, Select, useSelect } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { useMe } from "context/MeContext";
import removeMarkdown from "markdown-to-text";
import ReactQuill from "react-quill";
import toolbarOptions from "components/TextEditor/toolbarOptions";

export const PostCreate: React.FC = () => {
  const { me } = useMe();
  const { push } = useNavigation();
  const { formProps, saveButtonProps } = useForm({
    resource: "post",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/forum/post");
    },
  });
  const { selectProps } = useSelect({
    resource: "topic",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          user: {
            id: me?.id,
          },
        }}
      >
        <Form.Item
          className="w-100"
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item> 
        <Form.Item className="w-100" label={"Content"} name="content" >
          {/* <MDEditor
            data-color-mode="light"
            onChange={(e: string | undefined) => {
              formProps.form?.setFieldValue("content_raw", removeMarkdown(e || ""));
            }}
          /> */}
          <ReactQuill theme="snow" modules={{ toolbar: toolbarOptions }} onChange={(e: string | undefined) => {
              formProps.form?.setFieldValue("content_raw", removeMarkdown(e || ""));
            }}/>
        </Form.Item>
        <Form.Item className="w-100" hidden name="content_raw">
          <Input />
        </Form.Item>
        <Form.Item
          label={"Topic"}
          name={["topic", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
          className="w-full"
        >
          <Select {...selectProps} placeholder="Topic" />
        </Form.Item>
        <Form.Item name={["user", "id"]} hidden>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
