import { useResource } from "@pankod/refine-core";
import { useEffect } from "react";

export default function ScrollToTop() {
  const { resourceName } = useResource();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, [resourceName]);

  return null;
}
