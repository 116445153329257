import React from "react";
import "./forumsNewPost.scoped.scss";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { Input, Form } from "antd";
import { useMe } from "context/MeContext";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMessage, faBell, faReply } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useCreate, useNavigation } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { ForumLayout } from "components/Layout/Forum/forum-layout";
import removeMarkdown from "markdown-to-text";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toolbarOptions from "../../components/TextEditor/toolbarOptions.js";
library.add(far, faMessage);
library.add(fas, faBell);
library.add(fas, faReply);

const { TextArea } = Input;

const ForumsNewPost: React.FC = () => {
  const { push } = useNavigation();

  const { path } = useCompany();
  const { me: user } = useMe();
  const [form] = Form.useForm();
  const { mutateAsync: createPost } = useCreate();
  const { topicId } = useParams();
  return (
    <ForumLayout>
      <div
        className=""
        css={css`
          margin-top: 20px;
        `}
      >
        <div
          className="title"
          css={css`
            font-size: 30px;
            font-weight: 700px !important;
          `}
        >
          NEW POST
        </div>
        <div className="table-wrapper">
          <Form
            form={form}
            onFinish={async (e) => {
              const res = await createPost({
                resource: "post",
                values: {
                  ...e,
                  topic: {
                    id: topicId,
                  },
                  user: {
                    id: user?.id,
                  },
                  content_raw: removeMarkdown(e.content),
                },
              });
              if (res.data) {
                push(`/${path}/forums/topic/${topicId}/post/${res.data.id}`);
              }
            }}
          >
            <Form.Item name="name">
              <TextArea placeholder="Title (maximum 150 characters)" autoSize maxLength={150} size="large" />
            </Form.Item>
            <Form.Item
              name={"content"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactQuill theme="snow" modules={{ toolbar: toolbarOptions }}/>
            </Form.Item>

            <div className="post-button-group">
              <div className="post-button" onClick={() => form.submit()}>
                Post
              </div>
            </div>
          </Form>
        </div>
      </div>
    </ForumLayout>
  );
};

export default ForumsNewPost;
