import { PlusOutlined } from "@ant-design/icons";
import { useForm, Form, Select, useSelect, Edit, Col, Row } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { CardAdmin } from "components/Card/CardAdmin";
import toolbarOptions from "components/TextEditor/toolbarOptions";
import React from "react";
import ReactQuill from "react-quill";
import { ResourcePlacement } from "types/resource";
import { ResourceDetails } from "./components/resource-details";

export const placementOps = Object.entries(ResourcePlacement).map((resource) => {
  return {
    value: resource[1],
    label: resource[0],
  };
});
export const ResourcesEdit: React.FC = () => {
  const { push } = useNavigation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams();

  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: "resource",
    redirect: false,
    id,
    onMutationSuccess: () => {
      push("/admin/resources");
    },
  });
  const { selectProps } = useSelect({
    resource: "dimension",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={queryResult?.isLoading}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(e: any) => {
          // e.url = e.type === ResourceType.Web ? e.url : files[0];
          formProps.onFinish?.(e);
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={12}>
            <CardAdmin title="General information">
              <div className="flex space-x-2">
                <Form.Item
                  className="w-1/2"
                  label={"Dimension"}
                  name="dimension_id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select {...selectProps} />
                </Form.Item>
              </div>
              <Form.Item
                className=""
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactQuill
                  style={{
                    height: 100,
                  }}
                  theme="snow"
                  modules={{ toolbar: toolbarOptions }}
                />
              </Form.Item>
              <Form.Item
                className="w-1/2 mt-[120px]"
                label={"Placement"}
                name="placement"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={placementOps} />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={12}>
            <CardAdmin title="Details">
              <Form.List name={"resource_details"}>
                {(fields, { add, remove }, { errors }) => (
                  <div>
                    {fields.map((field) => {
                      return (
                        <ResourceDetails
                          key={field.key}
                          field={field}
                          form={formProps?.form}
                          setLoading={setLoading}
                          loading={loading}
                          remove={remove}
                        />
                      );
                    })}
                    <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

                    {fields.length < 1000 && (
                      <div onClick={() => add()} className="cursor-pointer flex justify-end">
                        <PlusOutlined className="mr-2" />
                        Add more
                      </div>
                    )}
                  </div>
                )}
              </Form.List>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

