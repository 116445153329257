import React from "react";
import { DatePicker, Input, InputNumber, Select } from "antd";
import { DataType } from "types/challenge";
import { ILevelActivity } from "types/activity";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { UserActivityTrackingScore } from "types/user-activity";
import "./action-plan.scoped.scss";

dayjs.extend(weekday);

interface AnswerDataTypeProps {
  data_type: DataType;
  setAnswer: React.Dispatch<any>;
  item: ILevelActivity;
  isDisabled: boolean;
  currentLevelActivity?: UserActivityTrackingScore;
}

export const AnswerDataType: React.FC<AnswerDataTypeProps> = (props) => {
  const { data_type, ...restProps } = props;
  switch (data_type) {
    case "Yes/No":
      return <YesNoType {...restProps} />;
    case "Numeric":
      return <NumericType {...restProps} />;
    case "Dropdown":
      return <DropdownType {...restProps} />;
    case "Date":
      return <DateType {...restProps} />;
    default:
      return <FreeTextType {...restProps} />;
  }
};

const YesNoType: React.FC<Omit<AnswerDataTypeProps, "data_type">> = ({
  setAnswer,
  isDisabled,
  currentLevelActivity,
}) => {
  return (
    <Select
      disabled={isDisabled}
      className="data-type"
      placeholder="Select an answer"
      options={[
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ]}
      defaultValue={currentLevelActivity?.answer}
      onChange={(e) => {
        setAnswer(e);
      }}
    />
  );
};

const DateType: React.FC<Omit<AnswerDataTypeProps, "data_type">> = ({
  setAnswer,
  isDisabled,
  currentLevelActivity,
}) => {
  return (
    <div className="data-type">
      <DatePicker
        disabled={isDisabled}
        onChange={(value) => {
          setAnswer(dayjs(value).format("YYYY-MM-DD"));
        }}
        style={{ width: "100%" }}
        defaultValue={dayjs(currentLevelActivity?.answer)}
      />
    </div>
  );
};

const NumericType: React.FC<Omit<AnswerDataTypeProps, "data_type">> = ({
  setAnswer,
  isDisabled,
  currentLevelActivity,
}) => {
  return (
    <div className="data-type">
      <InputNumber
        type="number"
        disabled={isDisabled}
        onChange={(e) => {
          setAnswer(e);
        }}
        style={{ width: "100%" }}
        defaultValue={currentLevelActivity?.answer}
      />
    </div>
  );
};

const FreeTextType: React.FC<Omit<AnswerDataTypeProps, "data_type">> = ({
  setAnswer,
  isDisabled,
  currentLevelActivity,
}) => {
  return (
    <div className="data-type">
      <Input
        disabled={isDisabled}
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
        style={{ width: "100%" }}
        defaultValue={currentLevelActivity?.answer}
      />
    </div>
  );
};

const DropdownType: React.FC<Omit<AnswerDataTypeProps, "data_type">> = ({
  item,
  setAnswer,
  isDisabled,
  currentLevelActivity,
}) => {
  const opts = item.results?.[DataType.Dropdown]?.map((data: any) => {
    return {
      value: data.answer,
      label: data.answer,
    };
  });
  return (
    <Select
      disabled={isDisabled}
      className="data-type"
      placeholder="Select an answer"
      options={opts}
      onChange={(e) => {
        setAnswer(e);
      }}
      defaultValue={currentLevelActivity?.answer}
    />
  );
};
