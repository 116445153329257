export enum ResourceType {
  PDF = "PDF",
  Web = "Link",
}

export enum ResourcePlacement {
  RightBottom = "rightBottom",
  TopRight = "topRight",
  RightTop = "rightTop",
  BottomLeft = "bottomLeft",
  TopLeft = "topLeft",
  BottomRight = "bottomRight",
  LeftBottom = "leftBottom",
  LeftTop = "leftTop",
}
