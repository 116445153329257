import React from "react";
import "./updateStats.scoped.scss";
import { YesNoType } from "./components/YesNoType";
import { NumericType } from "./components/NumericType";
import { DropdownType } from "./components/DropdownType";
import { DateType } from "./components/DateType";
import { DataType } from "types/challenge";
import { FreeTextType } from "./components/FreeTextType";

interface StatTypeProps {
  data?: any;
  userResult: any;
  setUserResult: React.Dispatch<any>;
  week: number;
  stats: any[];
  order: number;
  disabled: boolean;
}

export interface StatTypeChildProps extends StatTypeProps {
  onUpdateChild?: (value: any, user_answer: any, item: any) => any[];
  setLoading: (value: boolean) => void;
  loading: boolean;
  files: string[];
  callback: (value: string) => void;
  onRemoveFile: (value: string) => void;
}

export const StatType: React.FC<StatTypeProps> = (props) => {
  const { data, stats, order, week, userResult, setUserResult } = props;
  const _stats = userResult?.[week] ? userResult[week] : stats;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<string[]>([]);
  const updateFileToAnswers = React.useCallback(
    (files: string[]) => {
      setUserResult((prev: any) => {
        return {
          ...prev,
          [week]: prev[week]?.map((item: any, key: any) => {
            if (order === key) {
              return {
                ...item,
                proof: files,
              };
            }
            return item;
          }),
        };
      });
    },
    [order, setUserResult, week],
  );
  const onSetFiles = React.useCallback(
    (proof: string) => {
      return setFiles((prev) => {
        const array = [...prev, proof];
        updateFileToAnswers(array);
        return array;
      });
    },
    [updateFileToAnswers],
  );
  const onRemoveFile = React.useCallback(
    (file: string) => {
      return setFiles((prev) => {
        const array = prev.filter((f) => f !== file);
        updateFileToAnswers(array);

        return array;
      });
    },
    [updateFileToAnswers],
  );
  const proof = userResult?.[week]?.[order]?.proof;

  React.useEffect(() => {
    setFiles(proof || []);
  }, [proof]);

  const onUpdateChild = React.useCallback(
    (value: any, user_answer: any, item: any) => {
      const _find = _stats?.some((_item: any, key: number) => key === order);
      if (!_find) {
        _stats.push(item);
      }
      return _stats?.map((item: any, key: any) => {
        if (order === key) {
          return {
            ...data,
            user_answer,
            point: value,
            proof: files,
          };
        }
        return item;
      });
    },
    [_stats, data, files, order],
  );
  switch (data?.data_type) {
    case DataType.FreeText:
      return (
        <FreeTextType
          {...props}
          loading={loading}
          setLoading={setLoading}
          files={files}
          onRemoveFile={onRemoveFile}
          callback={onSetFiles}
          onUpdateChild={onUpdateChild}
        />
      );
    case DataType.Numeric:
      return (
        <NumericType
          {...props}
          loading={loading}
          setLoading={setLoading}
          files={files}
          onRemoveFile={onRemoveFile}
          callback={onSetFiles}
          onUpdateChild={onUpdateChild}
        />
      );
    case DataType.Dropdown:
      return (
        <DropdownType
          {...props}
          loading={loading}
          setLoading={setLoading}
          files={files}
          onRemoveFile={onRemoveFile}
          callback={onSetFiles}
          onUpdateChild={onUpdateChild}
        />
      );
    case DataType.Date:
      return (
        <DateType
          {...props}
          loading={loading}
          setLoading={setLoading}
          files={files}
          onRemoveFile={onRemoveFile}
          callback={onSetFiles}
          onUpdateChild={onUpdateChild}
        />
      );
    default:
      return (
        <YesNoType
          {...props}
          loading={loading}
          setLoading={setLoading}
          files={files}
          onRemoveFile={onRemoveFile}
          callback={onSetFiles}
          onUpdateChild={onUpdateChild}
        />
      );
  }
};
