import { useForm, Form, Input, Edit, Row, Col, InputNumber } from "@pankod/refine-antd";
import { useParams } from "@pankod/refine-react-router-v6";
import { CardAdmin } from "components/Card/CardAdmin";
import React from "react";
export const TrophyEdit: React.FC = () => {
  const { id } = useParams();
  const { formProps, saveButtonProps, formLoading } = useForm({
    resource: "trophy",
    id,
    redirect: false,
  });

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={12}>
            <CardAdmin title="General information">
              <Form.Item
                className="w-100"
                label={"Title"}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="w-100"
                label={"Rank"}
                name="rank"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Rank must be number and cannot be less than 0",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                className="w-100"
                label={"Trophy"}
                name="point"
                rules={[
                  {
                    required: true,
                    min: 0,
                    type: "number",
                    message: "Point must be number and cannot be less than 0",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
