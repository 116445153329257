import { CaretDownFilled, CloseSquareFilled } from "@ant-design/icons";
import { Modal, Progress, Space, Tag } from "@pankod/refine-antd";
import React, { useState, useEffect } from "react";
import "./action-plan.scoped.scss";

import { useList, useUpdate } from "@pankod/refine-core";
import { IActivity } from "types/activity";
import { config } from "config";
import { useMe } from "context/MeContext";
import { ActivityScore, IUserActivity, UserActivityResult, UserActivityTrackingScore } from "types/user-activity";
import { groupBy } from "lodash-es";
import { mapMedal } from "utils/mapMedal";
import { Popconfirm } from "antd";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { BlockAnswerComplete } from "./BlockAnswerComplete";
const { CheckableTag } = Tag;
interface ActionPlanProps {
  onRefetchUsers: () => void;
}
export interface UpdateHistory extends ActivityScore {
  activityId: string;
  levelActivityName: string;
  answer: string;
  proof: string[];
}
export const ActionPlan: React.FC<ActionPlanProps> = ({ onRefetchUsers }) => {
  const { myHRA, me: user, worstDimensions, company } = useMe();
  const [activities, setActivities] = useState<any>();
  const { action_plan } = useCompany();
  const find_color = action_plan?.find_your_activities_background_color;
  const {
    data: dataUserActivity,
    refetch: refetchUserActivityList,
    isFetching: isFetchingData,
    isLoading: isLoadingData,
  } = useList<IUserActivity>({
    resource: "user-activity",
    config: {
      filters: [
        {
          field: "userId",
          operator: "eq",
          value: user?.id,
        },
      ],
      pagination: {
        pageSize: 1,
      },
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });
  const userActivityData = dataUserActivity?.data[0];
  const currentActivities = userActivityData?.currentActivities || [];

  const result = userActivityData?.result || [];

  const [open, setOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [activitiesResult, setActivitiesResult] = React.useState<any[]>([]);

  const { data, isLoading } = useList<IActivity>({
    resource: "activity",
    config: {
      pagination: {
        pageSize: 1000,
      },
      filters: [
        {
          field: "company_id",
          operator: "eq",
          value: company?.id,
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(company?.id),
    },
  });

  useEffect(() => {
    const totalActivities = data?.data;
    const filteredActivities = totalActivities?.filter((activity) => activity.isHide === false);
    setActivities(filteredActivities);
  }, [data]);

  const randomActivitiesOld = Object.values(
    groupBy(
      activities?.filter((item: any) => {
        const worstDimension = worstDimensions?.find((dimension) => dimension.id === item.dimensionId);
        return worstDimension;
      }),
      "dimensionId",
    ),
  ).map((item) => {
    return item[0];
  });
  const recommendActivities = activities?.filter((item: any) => item.isRecommend === true);

  const randomActivities = [...randomActivitiesOld, ...(recommendActivities || [])];

  const _currentActivities1 = (activities?.filter((item: any) => currentActivities.includes(item.id)) || []).sort(
    (a: any, b: any) => currentActivities.indexOf(a.id) - currentActivities.indexOf(b.id),
  );

  const _currentActivities = [..._currentActivities1, ...(recommendActivities || [])];

  const _activities = currentActivities.length > 0 ? _currentActivities : randomActivities;
  const _activityIds = _activities?.map((item: any) => item.id);

  const { mutateAsync: updateUserActivity, isLoading: isLoadingUpdate } = useUpdate<IUserActivity>();

  React.useEffect(() => {
    if (isLoading || !activities) return;
    setActivitiesResult(
      activities.map((item: any) => {
        return {
          ...item,
          levelActivities: item.levelActivities.map((item: any, index: any) => {
            if (index === 0) {
              return {
                ...item,
                selected: true,
              };
            }
            return item;
          }),
        };
      }),
    );
  }, [activities, isLoading]);
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    if (!userActivityData?.id) {
      setOpen(false);
      return;
    }
    await updateUserActivity({
      resource: "user-activity",
      id: userActivityData.id,
      values: {
        currentActivities: [..._activityIds, ...selectedTags],
      },
      successNotification: {
        type: "success",
        message: "You have successfully added the activities!",
      },
    });
    refetchUserActivityList();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onSetSelected = (activityId: string, levelName: string) => {
    setActivitiesResult((curr) => {
      const _curr = curr.map((item) => {
        if (item.id === activityId) {
          const _levelActivities = item.levelActivities.map((ac: any) => {
            if (ac.name === levelName) {
              return {
                ...ac,
                selected: true,
              };
            }
            return {
              ...ac,
              selected: false,
            };
          });
          return {
            ...item,
            levelActivities: _levelActivities,
          };
        }
        return {
          ...item,
        };
      });
      return _curr;
    });
  };

  const findSelectedLevelActivity = (activityId: string) => {
    const selectedLevelActivity = activitiesResult.find((item) => item.id === activityId);
    const item = selectedLevelActivity?.levelActivities?.find((item: any) => item.selected);
    return item;
  };

  const onComplete = async (data: UpdateHistory) => {
    if (!userActivityData?.id) {
      return;
    }

    const { activityId, name, completed, levelActivityName, answer, proof } = data;
    let _result: UserActivityResult[] = [];
    const currentActivity = result?.find((item) => item.activityId === activityId);
    let dataUpdate: any;
    const history: UserActivityTrackingScore[] = currentActivity?.history || [];
    const existingLevel = history.find((item) => item.name === levelActivityName);

    dataUpdate = [
      ...history,
      {
        name,
        completed,
        answer,
        proof,
      },
    ];

    if (existingLevel) {
      dataUpdate = history.map((item) => {
        if (item.name === levelActivityName) {
          return {
            ...item,
            completed,
            answer,
            proof,
          };
        }
        return item;
      });
    }
    _result = [
      {
        activityId,
        history: dataUpdate,
      },
    ];
    const existingResult = result.find((item) => item.activityId === activityId);

    if (result.length) {
      const e = {
        activityId,
        history: dataUpdate,
      };
      _result = [...result, e];
      if (existingResult) {
        _result = result.map((item) => {
          if (item.activityId === activityId) {
            return {
              ...item,
              history: dataUpdate,
            };
          }
          return item;
        });
      }
    }
    const isAnswerNo = answer === "no";
    const calculatePoint = isAnswerNo ? 0 : history.length >= 1 ? history.length * 2 : 1;

    await updateUserActivity({
      resource: "user-activity",
      id: userActivityData.id,
      values: {
        result: _result,
        point: (userActivityData.point || 0) + calculatePoint,
        pointToPlus: calculatePoint,
        isZeroTrophy: isAnswerNo,
      },
      successNotification: false,
    });
    onRefetchUsers();
  };
  const removeItem = async (activityId: string) => {
    if (!userActivityData) return;
    const newActivities = _activities.filter((obj: any) => obj.id !== activityId).map((obj: any) => obj.id);
    const _result = result.filter((item) => item.activityId !== activityId);
    console.log({ _result });
    await updateUserActivity({
      resource: "user-activity",
      id: userActivityData.id,
      values: {
        currentActivities: newActivities,
        result: _result,
      },
      successNotification: {
        type: "success",
        message: "Your activity has been deleted!",
      },
    });
    refetchUserActivityList();
  };
  // if (!myHRA) return null;
  


  const buttonBackground: any = action_plan?.find_your_activities_background_color;
  return (
    <div className="action-plan chart-box" css={css``}>
      <h6
        className="box-title"
        css={css`
          color: ${action_plan?.title_color} !important;
        `}
      >
        ACTIVITIES
      </h6>
      {typeof myHRA !== "object" ? (
        <p className="wt-2">
          You can change or add more by clicking on the 'ADD ACTIVITY' button. We recommend performing at least 3
          activities per month to maintain health and wellness!
          <div style={{ fontSize: "13px" }}>*Please complete your HRA to get your recommended activities.</div>
        </p>
      ) : (
        <p className="wt-2">
          Based on your HRA results, we recommend that you start with these activities. You can change or add more by
          clicking on the 'ADD ACTIVITY' button. We recommend performing at least 3 activities per month to maintain
          health and wellness!
        </p>
      )}

      {_activities?.map((activity: any, index: any) => {
        const levelActivities = activity.levelActivities;
        const currentActivity = result.find((item) => item.activityId === activity.id);
        const l = currentActivity?.history.filter((item) => item.completed).length || 0;
        const percent = parseFloat(
          (
            ((currentActivity?.history.filter((item) => item.completed).length || 0) / (levelActivities.length || 0)) *
            100
          ).toFixed(1),
        );
        const { name } = findSelectedLevelActivity(activity.id) || {};

        return (
          <div
            key={index}
            className="activity-box"
            css={css`
              border: 1px solid ${action_plan?.border_color};
            `}
          >
            <div
              className="activity-name"
              css={css`
                background-color: ${action_plan?.border_color};
              `}
            >
              {activity.name}
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={() => {
                  removeItem(activity.id);
                }}
                okButtonProps={{ loading: isLoadingUpdate }}
                placement="topRight"
                className="delete-activity-group"
              >
                <span className="delete-activity">
                  <CloseSquareFilled />
                </span>
              </Popconfirm>
            </div>
            <div className="plan-name-box">
              <div className="plan-name">
                <strong>Activity: </strong> {activity.name}
              </div>
              <div className="plan-name">
                <strong>Current Level: </strong> {mapMedal(l)}
              </div>
            </div>
            <div className="j-between level-box">
              <div
                className="activity-progress"
                css={css`
                  span {
                    color: ${action_plan?.background_color} !important;
                  }
                `}
              >
                <Progress type="circle" percent={percent} width={170} strokeColor={action_plan?.background_color} />
              </div>
              <div className="level-container">
                {levelActivities.map((item: any, index: any) => {
                  const currentLevelActivity = currentActivity?.history.find((h) => h.name === item.name);
                  const previousItem = levelActivities[index - 1];
                  const preLevelActivity = currentActivity?.history.find((h) => h.name === previousItem?.name);
                  const isDisabled = index > 0 && !preLevelActivity?.completed;
                  return (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        if (isDisabled) return;
                        onSetSelected(activity.id, item.name);
                      }}
                      css={css`
                        .dis {
                          background-color: #d9dddc !important;
                        }
                      `}
                    >
                      <div
                        className={`level j-between ${
                          name === item.name ? "" : ""
                        } border-4 border-solid complete-box ${isDisabled ? "disabled dis" : ""}  ${
                          currentLevelActivity?.completed ? "completed dis" : ""
                        }`}
                        css={css`
                          background-color: ${action_plan?.background_color};
                          color: ${action_plan?.level_color};
                        `}
                      >
                        <div className="level-name w-3/5">{item.name}</div>
                        <div>
                          <div
                            className="flex justify-between space-x-5 items-center w-2/5"
                            css={css`
                              .data-type {
                                width: 180px;
                              }
                              .complete-button {
                                background-color: white;
                                border: none;
                                padding: 0.2rem 0.9rem;
                                font-size: 14px;
                                font-weight: 600;
                                &:hover,
                                &.disabled,
                                &.completed {
                                  transition: 0.3s ease;
                                  background-color: rgb(229, 229, 229);
                                  color: #c0c0c0;
                                }
                                &.completed {
                                  cursor: not-allowed;
                                }
                              }
                              .complete-box {
                                &.disabled,
                                &.completed {
                                  transition: 0.3s ease;
                                  background-color: rgba(229, 229, 229, 0.603);
                                  color: #c0c0c0;
                                }
                                &.disabled {
                                  pointer-events: none;
                                }
                              }
                            `}
                          >
                            <BlockAnswerComplete
                              item={item}
                              isLoadingUpdate={isLoadingUpdate}
                              isDisabled={isDisabled}
                              activity={activity}
                              onComplete={onComplete}
                              action_plan={action_plan}
                              currentLevelActivity={currentLevelActivity}
                              ready={Boolean(activity)}
                            />
                          </div>
                        </div>
                      </div>
                      {index !== levelActivities.length - 1 && (
                        <div
                          className="arrow"
                          css={css`
                            color: ${action_plan?.background_color};
                          `}
                        >
                          <CaretDownFilled />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}

      <button
        className="button-add"
        onClick={showModal}
        css={css`
          background-color: ${action_plan?.btn_add_activity_background_color} !important;
          &:hover {
            background-color: ${action_plan?.border_color} !important;
          }
        `}
      >
        ADD ACTIVITY
      </button>
      {/* ACTIVITIES LIST MODAL AFTER CLICK ON "ADD ACTIVITY" BUTTON*/}
      <Modal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoadingUpdate}
        okButtonProps={{ style: { background: buttonBackground } }}
        width={1000}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            css={css`
              color: ${action_plan?.find_your_activities_background_color};
              font-weight: 800;
            `}
          >
            FIND YOUR ACTIVITIES
          </h1>
          <p style={{ fontSize: 15 }}>Please choose which activities you want to perform this month</p>
        </div>
        <Space
          size={[14, 16]}
          wrap
          className="wrapper activity-board"
          css={css`
            .ant-tag-checkable {
              &:hover {
                background-color: ${action_plan?.find_your_activities_background_color};
              }
            }
            .ant-tag-checkable-checked {
              background-color: ${action_plan?.find_your_activities_background_color};
            }
          `}
        >
          {activities
            ?.filter((item: any) => !_activityIds?.includes(item.id))
            .map((tag: any) => (
              <CheckableTag
                key={tag.id}
                checked={selectedTags.includes(tag.id)}
                onChange={(checked) => handleChange(tag.id, checked)}
              >
                <div className="one-box">
                  <div className="tag-name">{tag.name}</div>
                  <div className="img">
                    <img src={`${config.ASSET_URL}${tag.icon}`} alt="activity" />
                  </div>
                </div>
              </CheckableTag>
            ))}
        </Space>
      </Modal>
    </div>
  );
};
