import { Button } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import React from "react";
import "./challengeTables.scoped.scss";

interface JoinIndividualProps {
  id?: string;
  refetchUserChallenge?: any;
}
export const JoinIndividual: React.FC<JoinIndividualProps> = ({ id, refetchUserChallenge }) => {
  const { mutateAsync: updateUserChallenge } = useUpdate();
  const onJoin = async () => {
    id &&
      (await updateUserChallenge({
        resource: "user-challenge",
        id,
        values: {
          mode_join: "individual",
        },
        successNotification: false,
      }));
    refetchUserChallenge?.();
  };
  return (
    <Button onClick={onJoin} className="c-button button-font join-button" style={{fontSize:17, width:"100%"}}>
      Join Individual
    </Button>
  );
};
