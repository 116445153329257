import { css } from "@emotion/react";
import { Button, Modal, Select, useModal, useSelect, Form, useForm, Input } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { useCompany } from "context/CompanyContext";
import React from "react";
import group from "../../assets/img/6.png";
import "./joinTeam.scoped.scss";
import "./challengeTables.scoped.scss";


interface JoinTeamProps {
  id?: string;
  refetchUserChallenge?: any;
  challengeId?: string | number;
}
export const JoinTeam: React.FC<JoinTeamProps> = ({ id, refetchUserChallenge, challengeId }) => {
  const [modeJoin, setModeJoin] = React.useState("");
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const { id: company_id } = useCompany();
  const { modalProps, show, close } = useModal();
  const { selectProps } = useSelect({
    resource: "team",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "companyId",
        operator: "eq",
        value: company_id,
      },
      {
        field: "challenge_id",
        operator: "eq",
        value: challengeId,
      },
    ],
    queryOptions: {
      enabled: Boolean(id),
    },
  });
  const { mutateAsync: updateUserChallenge } = useUpdate();
  const onJoin = async () => {
    id &&
      (await updateUserChallenge({
        resource: "user-challenge",
        id,
        values: {
          mode_join: "team",
          teamId: selectedTeam,
        },
        successNotification: false,
      }));
    refetchUserChallenge?.();
  };
  const onJoinTeam = async (teamId: string) => {
    id &&
      (await updateUserChallenge({
        resource: "user-challenge",
        id: id,
        values: {
          mode_join: "team",
          teamId,
        },
        successNotification: false,
      }));
    refetchUserChallenge?.();
  };
  const { formProps, form, formLoading } = useForm({
    resource: "team",
    redirect: false,
    onMutationSuccess: async (data) => {
      await onJoinTeam(data.data.id as string);
      form.resetFields();
      close();
    },
  });
  const onCreate = () => {
    form.submit();
  };

  const fnJoinTeam = () => {
    if (modeJoin === "exist") {
      onJoin();
      close();
      return;
    }
    onCreate();
  };

  return (
    <>
      <Button onClick={show} className="c-button join-button" style={{ width: "100%" }}>
        Join Team
      </Button>

      <Modal
        {...modalProps}
        centered
        title=""
        okText={"Join"}
        width={700}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        bodyStyle={{ marginBottom: "-32px" }}
      >
        <p className="join-text">You have the option to either join an existing team or create a new one</p>
        <div className="join-buttons j-between">
          <div className="join-team join">
            <Button
              className={`team-button button-48 ${modeJoin === "exist" ? "active" : ""}`}
              onClick={() => setModeJoin("exist")}
            >
              <span className="text">JOIN TEAM</span>
            </Button>
            <br />
            <br />
            <Select
              className="select-team"
              {...selectProps}
              onChange={(value: any) => {
                setSelectedTeam(value);
              }}
              style={{ display: "inline" }}
              disabled={Boolean(modeJoin === "new") || !modeJoin}
              placeholder="Select team"
              css={css`
                .ant-select-selector {
                  height: auto !important;
                }
              `}
            />
          </div>

          <div className="create-team join">
            <Button
              className={`team-button button-48 ${modeJoin === "new" ? "active" : ""}`}
              onClick={() => setModeJoin("new")}
            >
              <span className="text">CREATE TEAM</span>
            </Button>
            <br />
            <br />
            <Form
              {...formProps}
              initialValues={{
                companyId: company_id,
                challenge_id: challengeId,
              }}
              disabled={Boolean(modeJoin === "exist") || formLoading || !modeJoin}
            >
              <Form.Item
                label={""}
                name="name"
                className=""
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Name your team" />
              </Form.Item>
              <Form.Item
                label={"company id"}
                name="companyId"
                className=""
                hidden
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={"challenge_id"}
                name="challenge_id"
                className=""
                hidden
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Button className="btn-join" onClick={fnJoinTeam} loading={formLoading}>
            JOIN
          </Button>
        </div>
        <div>
          <img src={group} alt="group" />
        </div>
      </Modal>
    </>
  );
};
