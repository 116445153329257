import { InputNumber } from "antd";
import { UploadFiles } from "components/Upload/UploadFile";
import { DataType } from "types/challenge";
import { StatTypeChildProps } from "../StatType";
import "../updateStats.scoped.scss"

export const NumericType: React.FC<StatTypeChildProps> = ({
  data,
  userResult,
  setUserResult,
  week,
  onUpdateChild,
  order,
  disabled,
  files,
  onRemoveFile,
  setLoading,
  loading,
  callback,
}) => {
  const onUpdate = async (user_answer: number) => {
    const value =
      data?.results[DataType.Numeric].find((item: { from: number; to: number }) => {
        return user_answer >= item.from && user_answer <= item.to;
      })?.point || 0;
    const _child = onUpdateChild?.(value, user_answer, data);
    const _userResult = {
      ...userResult,
      [week]: _child,
    };
    setUserResult(_userResult);
  };
  const answer = userResult?.[week]?.[order]?.user_answer;

  return (
    <div className="single-activity">
      <hr className="single-activity-hr" />
      <div className="j-between single-activity-group">
        <div className="show-on-responsive one">
          <div className="left-col col">{data.title}</div>
          <div className="center-col col">{data.description}</div>
        </div>
        <div className="left-col col show-on-lg">{data.title}</div>
        <div className="center-col col show-on-lg">{data.description}</div>
        <div className="right-col j-between col buttons two">
          <InputNumber
            min={0}
            onChange={(value) => onUpdate(value || 0)}
            value={answer || 0}
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </div>
        <div className="right-right-col col upload-col three">
          <UploadFiles
            key={files[files.length - 1]}
            isDisabled={disabled}
            loading={loading}
            setLoading={setLoading}
            callback={callback}
            onRemoveFile={onRemoveFile}
            files={files}
          />
        </div>
      </div>
    </div>
  );
};
