import { useForm, Form, Input, Create, InputNumber, Select, useSelect, Skeleton } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import React from "react";

export const EmployeeTypeCreate: React.FC = () => {
  const { push } = useNavigation();
  const { company } = useMe();
  const { formProps, saveButtonProps } = useForm({
    resource: "employee-type",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/metadata/employee-type");
    },
  });
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: company.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company.id),
    },
  });

  const defaultCompany = selectProps.options?.find((item) => item.value)?.value;

  return (
    <Create saveButtonProps={saveButtonProps}>
      {!defaultCompany ? (
        <Skeleton />
      ) : (
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            company: {
              id: defaultCompany,
            },
          }}
        >
          <Form.Item
            className="w-100"
            label={"Name"}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Company"}
            name={["company", "id"]}
            rules={[
              {
                required: true,
              },
            ]}
            className="w-full"
          >
            <Select {...selectProps} placeholder="Company" disabled />
          </Form.Item>
          <Form.Item className="w-100" label={"Order"} name="order">
            <InputNumber />
          </Form.Item>
        </Form>
      )}
    </Create>
  );
};
