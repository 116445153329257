import { Avatar, DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useMe } from "context/MeContext";
import dayjs from "dayjs";
import React from "react";
export const Admin: React.FC = () => {
  const { isAdmin, isSuperAdmin, company } = useMe();
  const { tableProps } = useTable({
    resource: "users",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "companyId",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
  });

  const columns = [
    {
      title: "Avatar",
      dataIndex: "icon",
      key: "icon",
      render: (val: string) => {
        return <Avatar src={val} />;
      },
      width: "5%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      render: (val: string, record: any) => {
        return (
          <>
            {val} {record.lastname}
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Employer",
      dataIndex: ["__company__", "name"],
      key: "Company",
    },
    {
      title: "Employer code",
      dataIndex: "employeeCode",
      key: "employeeCode",
    },
    {
      title: "Date of birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (val: string) => {
        return <>{val ? dayjs(val).format("YYYY-MM-DD HH:mm:ss") : "-"}</>;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => {
        return <>{dayjs(val).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} disabled={!(isAdmin || isSuperAdmin)} />
            <DeleteButton
              disabled={!(isAdmin || isSuperAdmin)}
              shape="circle"
              hideText
              recordItemId={record.id}
              resourceNameOrRouteName="users"
            />
          </div>
        );
      },
    },
  ];

  return (
    <List title="Users" canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
  );
};
