import { useForm, Form, Input, Edit, Row, Col, Select, InputNumber, Switch, useSelect } from "@pankod/refine-antd";
import { useParams } from "@pankod/refine-react-router-v6";
import { CardAdmin } from "components/Card/CardAdmin";
import { UploadImage } from "components/Upload/Upload";
import React, { useState } from "react";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { config } from "config";
import { IDimension } from "types/dimension";
import { useMe } from "context/MeContext";
import { dataTypeOps } from "../Challenge/edit";
import { ListActivityResult } from "./components/ListActivityResult";

export const ActivityEdit: React.FC = () => {
  const [disabledHide, setDisabledHide] = useState(false)
  const [disabledRecommend, setDisabledRecommend] = useState(false)
  const { id } = useParams();
  const { company, isSuperAdmin } = useMe();

  const { formProps, saveButtonProps, form, queryResult, formLoading } = useForm({
    resource: "activity",
    id,
    redirect: false,
  });

  const [loading, setLoading] = useState(false);
  const data = queryResult?.data?.data;
  const icon = config.ASSET_URL + data?.icon;

  const { selectProps: dimensionProps } = useSelect<IDimension>({
    resource: "dimension",
    optionLabel: "name",
  });

  const { selectProps, queryResult: queryResultCompany } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
    filters: !isSuperAdmin
      ? [
          {
            field: "id",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
  });
  const onChangeRecommendSwitch = (checked: boolean) => {
    disabledHide ? setDisabledHide(false) : setDisabledHide(true);
  }
  
  const onChangeHideSwitch = (checked: boolean) => {
    disabledRecommend ? setDisabledRecommend(false) : setDisabledRecommend(true);
  }
  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading || queryResultCompany?.isLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <CardAdmin title="General information">
              <Form.Item
                label={"Order"}
                name="order"
                className="w-[200px]"
                rules={[
                  {
                    required: true,
                    message: "Order must be greater than or equal 0",
                  },
                ]}
              >
                <InputNumber type={"number"} min={0} />
              </Form.Item>
              <Form.Item
                label={"Company"}
                name={"company_id"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="w-full"
              >
                <Select disabled={!isSuperAdmin} {...selectProps} placeholder="Company" />
              </Form.Item>
              <div className="flex space-x-2">
                <Form.Item
                  className="w-1/2"
                  label={"Name"}
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="w-1/2"
                  label={"Dimension"}
                  name="dimensionId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select {...dimensionProps} />
                </Form.Item>
              </div>
              <div className="flex space-x-2">
                <Form.Item
                  label={"Icon"}
                  name="icon"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <UploadImage
                    setLoading={setLoading}
                    loading={loading || formLoading}
                    form={form}
                    url={!formLoading ? icon : ""}
                  />
                  <div>
                    Icon Library:{" "}
                    <a href="https://fontawesome.com/search" target="_blank" rel="noopener noreferrer">
                      click here
                    </a>
                  </div>
                </Form.Item>
              </div>
              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item label={"Recommend"} name="isRecommend" className="w-[200px]" valuePropName="checked">
                <Switch disabled={disabledRecommend} onChange={onChangeRecommendSwitch}/>
              </Form.Item>
              <Form.Item label={"Hide"} name="isHide" className="w-[200px]" valuePropName="checked">
                <Switch disabled={disabledHide} onChange={onChangeHideSwitch} />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={8}>
            <CardAdmin title="Levels">
              <Form.List name={"levelActivities"}>
                {(fields, { add, remove }, { errors }) => (
                  <div>
                    {fields.map((field) => {
                      return (
                        <div key={field.key} className="relative border border-gray-300 border-solid rounded p-2 mb-3">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "name is required!",
                              },
                            ]}
                            label={`Name`}
                            name={[field.name, "name"]}
                            className="w-full pr-[30px]"
                          >
                            <Input className="font-normal" />
                          </Form.Item>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Description is required!",
                              },
                            ]}
                            label={`Data Type`}
                            name={[field.name, "data_type"]}
                            className="w-full pr-[30px]"
                          >
                            <Select options={dataTypeOps} className="font-normal" />
                          </Form.Item>
                          <h4>Results</h4>
                          <ListActivityResult field={field} />
                          <CloseCircleFilled
                            className="absolute right-[5px] top-[5px] text-red-400 text-[20px] cursor-pointer"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </div>
                      );
                    })}
                    <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

                    {fields.length < 10 && (
                      <div onClick={() => add()} className="cursor-pointer flex justify-end">
                        <PlusOutlined className="mr-2" />
                        Add more
                      </div>
                    )}
                  </div>
                )}
              </Form.List>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
