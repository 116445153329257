import { ResponsiveBar } from "@nivo/bar";
import imgWellness from "../../assets/img/yoga.png";
import React from "react";
import { useMe } from "context/MeContext";
import { capitalize } from "lodash-es";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
import { BgColorsOutlined } from "@ant-design/icons";
import { Button } from "antd" 
import routerProvider from "@pankod/refine-react-router-v6";
import "./risk.scoped.scss"
import d51calendar from "../../assets/img/d51calendar.jpg";


const { Link } = routerProvider; 

export const EmployerSpace: React.FC = () => {
  const { risk_areas, path: company_path, brand, homepage } = useCompany();
  const color = brand?.color_primary;
  return (
    <div className="employer-space" css={css`
      margin: 100px 30px 0 30px;
      .title {
        color: ${color};
      }
    `}>
      <div className="title">{homepage?.home_title}</div>
      <div dangerouslySetInnerHTML={{ __html: homepage?.home_description }} className="text" />
      <div className="img-border">
        <img src={generateURL(homepage?.home_bg)} alt="" />
      </div>
    </div>
  );
};

export default EmployerSpace;

