import React from "react";
import { RefineLayoutHeaderProps } from "@pankod/refine-ui-types";
import { Layout, Typography, Avatar, Space } from "antd";
import { useMe } from "context/MeContext";
import { Dropdown } from "@pankod/refine-antd";
import empty from "assets/img/empty_ava.png";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { useLogout } from "@pankod/refine-core";
const { Text } = Typography;

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const { me: user } = useMe();
  const { mutate: logout } = useLogout();
  const shouldRenderHeader = user && (user.username || user.icon);
  const profile = (
    <div
      className="flex flex-col bg-white px-4 rounded-md"
      css={css`
        span {
          ${tw`text-[#00899f] py-1 w-full`}
        }
      `}
    >
      <span>
        <Text ellipsis className="text-black">
          {user?.username}
        </Text>
      </span>
      <span className="border-t py-2">
        <Text
          strong
          className="shadow-none border-none cursor-pointer"
          onClick={() => {
            logout();
          }}
        >
          Sign out
        </Text>
      </span>
    </div>
  );
  return shouldRenderHeader ? (
    <Layout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "100px",
        backgroundColor: "#FFF",
      }}
    >
      <Dropdown className="ml-8" overlay={profile} placement="bottomRight">
        <Space>
          <Avatar size="large" src={user?.icon || empty} alt={user?.firstname + " " + user?.lastname} />
        </Space>
      </Dropdown>
    </Layout.Header>
  ) : null;
};
