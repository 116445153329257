import { axiosInstance } from "./authProvider";
import { DataProvider as DataProvider$1 } from "@pankod/refine-core";
import nestjsxCrudDataProvider from "@pankod/refine-nestjsx-crud";
import { config } from "config";
import { RequestQueryBuilder, QueryJoin, QueryJoinArr } from "@nestjsx/crud-request";
const apiUrlBE = config.API_URL;
const handleJoin = (query: RequestQueryBuilder, join?: QueryJoin | QueryJoinArr | (QueryJoin | QueryJoinArr)[]) => {
  if (join) {
    query.setJoin(join);
  }
  return query;
};
export const CustomNestjsDataProvider = (apiUrl: string, httpClient: any = axiosInstance): DataProvider$1 => ({
  ...nestjsxCrudDataProvider(apiUrl, httpClient),
  getOne: async ({ resource, id, metaData }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    let query = RequestQueryBuilder.create();
    query = handleJoin(query, metaData?.join);
    const { data } = await httpClient.get(`${url}?${query.query(true)}`);

    return {
      data,
    };
  },
});
export const nestJsDataProvider = CustomNestjsDataProvider(apiUrlBE, axiosInstance);
