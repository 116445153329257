import { useForm, Form, Input, Select, Edit, Row, Col, DatePicker } from "@pankod/refine-antd";
import { optionsGender } from "pages/Auth/SocialRegister/SocialRegister";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "@pankod/refine-react-router-v6";
import { Role } from "types/user";
import { useMe } from "context/MeContext";

export const RoleOptions = [
  {
    value: Role.ADMIN,
    label: Role.ADMIN,
  },
  {
    value: Role.MEMBER,
    label: Role.MEMBER,
  },
];

export const UserEdit: React.FC = () => {
  const { id } = useParams();

  const { isAdmin, isSuperAdmin, me } = useMe();
  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: "users",
    id,
    redirect: false,
  });
  const initialValues = {
    dateOfBirth: dayjs(queryResult?.data?.data.dateOfBirth),
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          ...initialValues,
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <Form.Item
              label={"Firstname"}
              name="firstname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Lastname"}
              name="lastname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Email"}
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={"Username"}
              name="username"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Date of birth"}
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"YYYY-MM-DD"} className="w-full" />
            </Form.Item>

            <Form.Item
              label={"Gender"}
              name="gender"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={optionsGender} placeholder="Birth Sex" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <div className="flex space-x-2">
              <Form.Item
                label={"Role"}
                name="role"
                rules={[
                  {
                    required: false,
                  },
                ]}
                className="w-full"
              >
                <Select disabled={!(isAdmin || isSuperAdmin) || id === me?.id} options={RoleOptions} />
              </Form.Item>
              <Form.Item
                label={"Employer code"}
                name="employeeCode"
                rules={[
                  {
                    required: false,
                  },
                ]}
                className="w-full"
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
