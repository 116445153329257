import React, { useState } from "react";
import "./updateStats.scoped.scss";
import { useList, useUpdate } from "@pankod/refine-core";
import pluralize from "pluralize";
import { StatType } from "./StatType";
import { css } from "@emotion/react";
import { Spin } from "@pankod/refine-antd";
import { useChallengeContext } from "context/ChallengeContext";
import { Tooltip, Modal } from "antd";
import dayjs from "dayjs";
import { JoinTeam } from "../../components/ChallengeTables/JoinTeam";
import { generateURL } from "utils/generateURL";
interface UpdateStatsProps {
  selectedChallenge: number | string;
  user_challenge?: any;
  refetchUserChallenge?: any;
  isLoading?: boolean;
  challenge?: any;
  isLoadingChallenge?: boolean;
}

const UpdateStats: React.FC<UpdateStatsProps> = ({
  selectedChallenge,
  user_challenge,
  refetchUserChallenge,
  isLoading,
  challenge,
  isLoadingChallenge,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { onRefetch } = useChallengeContext();
  const [userResult, setUserResult] = React.useState<any>(user_challenge?.current_challenge || {});
  React.useEffect(() => {
    setUserResult(user_challenge?.current_challenge);
  }, [user_challenge?.current_challenge]);

  const { data: dataRawTeam } = useList({
    resource: "user-challenge",
    config: {
      filters: [
        { field: "challenge_id", operator: "eq", value: selectedChallenge },
        { field: "teamId", operator: "eq", value: user_challenge?.team?.id },
        { field: "mode_join", operator: "eq", value: "team" },
      ],
    },
    queryOptions: {
      enabled: Boolean(user_challenge?.team?.id),
    },
  });
  const teamPoint = dataRawTeam?.data.reduce((obj, item) => {
    return obj + item.point;
  }, 0);
  const { mutateAsync: updateUserChallenge } = useUpdate();
  const onUpdateUserChallenge = async (pointToPlus: number) => {
    user_challenge?.id &&
      (await updateUserChallenge({
        resource: "user-challenge",
        id: user_challenge?.id,
        values: {
          current_challenge: userResult,
          pointToPlus,
        },
        successNotification: {
          type: "success",
          message: "Successfully saved",
        },
      }));
    refetchUserChallenge?.();
    onRefetch();
  };

  const time = dayjs(challenge?.start_date)
    .startOf("day")
    .valueOf();
  const time1 = dayjs(challenge?.start_date)
    .startOf("day")
    .add(1, "day")
    .valueOf();
  const timeAdded = challenge?.duration * 7 * 86400000;
  const exp_date = new Date(timeAdded + time);

  const now = dayjs().valueOf();

  const currentWeek = (now - time) / (7 * 86400000);
  const currentWeek1 = (now - time1) / (7 * 86400000);
  const stats: any[] = challenge?.stats || [];
  const isJoined = user_challenge?.mode_join;
  const current_point_of_this_week =
    Object.values(user_challenge?.current_challenge || {})
      ?.flat()
      ?.reduce?.((point: number, item: any) => {
        return point + (item.point || 0);
      }, 0) || 0;
  const new_point_of_this_week = Object.values(userResult || {})
    ?.flat()
    ?.reduce?.((point: number, item: any) => {
      return point + (item.point || 0);
    }, 0);

  const point_to_plus =
    current_point_of_this_week === new_point_of_this_week ? 0 : new_point_of_this_week - current_point_of_this_week;
  const { mutateAsync: leaveTeam } = useUpdate();

  const onLeaveTeam = async () => {
    await leaveTeam({
      resource: "user-challenge/leave-team",
      id: user_challenge?.id,
      values: {
        teamId: undefined,
        mode_join: "individual",
      },
    });
    refetchUserChallenge?.();
    onRefetch();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onLeaveTeam();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="container img-chall">
        {challenge?.header_img ? <img src={generateURL(challenge?.header_img)} alt="img" /> : null}
      </div>
      <div className="container img-chall">
        {challenge?.scoring_img ? <img src={generateURL(challenge?.scoring_img)} alt="img" /> : null}
      </div>
      <div
        className="container updateStats-table relative"
        css={css`
          .single-week-table {
            border: 1px solid #f08f63;
            align-items: center;
            margin-top: 30px;
            color: #5e5e5e;
            hr {
              background-color: #f08f63;
              height: 0.7px;
              border: 0;
              margin: 0;
            }
            .table-title {
              background-color: rgba(240, 143, 99, 0.2);
              color: #f08f63;
              text-align: left;
              font-size: 17px;
              font-weight: 600;
              padding: 15px 30px;
            }
            .single-activity {
              .col {
                padding: 15px 30px;
                align-items: center;
                text-align: left;
                font-size: 15px;
              }
              .left-col,
              .right-col {
                width: 30%;
              }
              .center-col {
                font-weight: 400;
                width: 40%;
                border-left: 0.7px solid #f08f63;
                border-right: 0.7px solid #f08f63;
              }
              .left-col {
                font-weight: 500;
              }
            }
          }
          .buttons {
            display: flex;
            // width: 380px;
            gap: 10px;
            --b: 2px; /* the border thickness */
            --h: 30px; /* the height */
            button:hover,
            button:active:not(:focus-visible),
            button.active {
              --_s: 0.75;
            }
            button:active,
            button.active {
              box-shadow: inset 0 0 0 100vmax var(--_c);
              color: #fff;
            }
          }

          .buttons button {
            --_c: #37848a;
            flex: calc(1.25 + var(--_s, 0));
            min-width: 0;
            font-size: 17px;
            font-weight: bold;
            height: var(--h);
            cursor: pointer;
            color: var(--_c);
            border: var(--b) solid var(--_c);
            background: conic-gradient(at calc(100% - 1.3 * var(--b)) 0, var(--_c) 209deg, #0000 211deg) border-box;
            clip-path: polygon(0 0, 100% 0, calc(100% - 0.577 * var(--h)) 100%, 0 100%);
            padding: 0 calc(0.288 * var(--h)) 0 0;
            margin: 0 calc(-0.288 * var(--h)) 0 0;
            box-sizing: border-box;
            transition: flex 0.4s;
          }
          .buttons button + button {
            --_c: #f08f63;
            flex: calc(0.75 + var(--_s, 0));
            background: conic-gradient(from -90deg at calc(1.3 * var(--b)) 100%, var(--_c) 119deg, #0000 121deg)
              border-box;
            clip-path: polygon(calc(0.577 * var(--h)) 0, 100% 0, 100% 100%, 0 100%);
            margin: 0 0 0 calc(-0.288 * var(--h));
            padding: 0 0 0 calc(0.288 * var(--h));
          }
          .buttons button:focus-visible {
            outline-offset: calc(-2 * var(--b));
            outline: calc(var(--b) / 2) solid #000;
            background: none;
            clip-path: none;
            margin: 0;
            padding: 0;
          }
          .buttons button:focus-visible + button {
            background: none;
            clip-path: none;
            margin: 0;
            padding: 0;
          }
          .buttons button:has(+ button:focus-visible) {
            background: none;
            clip-path: none;
            margin: 0;
            padding: 0;
          }
        `}
      >
        {!isJoined && (
          <div className="absolute top-0 left-0 w-full h-full bg-black/[0.75] z-[99] flex items-center justify-center text-white text-[30px]">
            Please Select The Challenge
          </div>
        )}
        <div className="stats-title relative">
          UPDATE STATS
          <br />
          <span>How to Score Points in the Challenge</span>
        </div>
        <div className="stats-content stat-load">
          <div className="challenge-info j-between challenge-top ch-left">
            <div className="ch-name">
              <div className="ch-top">CHALLENGE NAME</div>
              <div className="ch-bottom">{challenge?.name}</div>
            </div>
            <div className="ch-des">
              <div className="ch-top">CHALLENGE DESCRIPTION</div>
              <div className="ch-bottom">{challenge?.description}</div>
            </div>
            <div className="ch-duration">
              <div className="ch-top">CHALLENGE DURATION</div>
              <div className="ch-bottom">{challenge?.duration + " " + pluralize("week", challenge?.duration)}</div>
            </div>
          </div>
          <br />
          <div className="hr"></div>
          <div className="challenge-info j-between">
            <div className="ch-name">
              <div className="ch-top">CHALLENGE STATUS</div>
              <div className="ch-bottom">
                Join {user_challenge?.mode_join}
                {user_challenge?.mode_join === "individual" ? (
                  <Tooltip
                    title="Considering a team? It's never too late! Click to join, and let's embark on this journey together."
                    color="volcano"
                  >
                    {challenge?.allow_team && (
                      <div className="join-team-button">
                        <JoinTeam
                          refetchUserChallenge={refetchUserChallenge}
                          id={user_challenge?.id}
                          challengeId={selectedChallenge}
                        />
                      </div>
                    )}
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            {user_challenge?.mode_join === "team" ? (
              <div className="ch-des">
                <div className="ch-top">TEAM NAME</div>
                <div className="ch-bottom">
                  {user_challenge?.team?.name}
                  <Tooltip title="Leave team" color="volcano">
                    {/* <button className="button-leave" onClick={onLeaveTeam}>
                      X
                    </button> */}
                    <button className="button-leave" onClick={showModal}>
                      X
                    </button>
                    <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                      <p>Are you sure you want to leave your team?</p>
                      <i>
                        Note: Your challenge status will be changed after you leave your team. You can join or create
                        another team later!
                      </i>
                    </Modal>
                  </Tooltip>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="ch-duration">
              <div className="ch-top">CHALLENGE EXP DATE</div>
              <div className="ch-bottom">{dayjs(exp_date).format("MM/DD/YYYY")}</div>
            </div>
          </div>

          <div className="save-button-group">
            <p className="save-button-des">Complete your task and finalize your achievement by clicking "Save Task"</p>
            <button
              className="button-save glow-on-hover"
              onClick={async () => await onUpdateUserChallenge(point_to_plus)}
            >
              SAVE TASK
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            {user_challenge?.mode_join === "team" ? (
              <div className="score-group j-between">
                <div className="score-info">
                  <div className="your-score">Your Score</div>
                  <div className="score"> {current_point_of_this_week}</div>
                </div>
                <div className="score-info">
                  <div className="your-score team">Your Team Score</div>
                  <span className="score">
                    <span className="not-in-team">
                      <div className="score"> {teamPoint}</div>
                    </span>
                  </span>
                </div>
              </div>
            ) : (
              <div className="score-group">
                <div className="score-info">
                  <div className="your-score">Your Score</div>
                  <div className="score"> {current_point_of_this_week}</div>
                </div>
              </div>
            )}
          </div>
          <Spin spinning={isLoading || isLoadingChallenge}>
            {Array.from(Array(challenge?.duration || 0).keys()).map((n) => {
              const week = n + 1;
              const isWeekActive = n + 1 > currentWeek1 && n < currentWeek;
              //const isWeekActive = n < currentWeek;
              return (
                <div key={n} className="single-week-table relative">
                  {!isWeekActive && <div className="bg-black/[0.5] absolute top-0 left-0 w-full h-full z-10" />}
                  <div className="table-title flex j-between">
                    <span>WEEK {week}</span>
                  </div>
                  {stats?.map((item, key) => {
                    return (
                      <StatType
                        disabled={!isWeekActive}
                        key={`${item.title}${key}`}
                        order={key}
                        week={n + 1}
                        data={item}
                        userResult={userResult}
                        setUserResult={setUserResult}
                        stats={stats}
                      />
                    );
                  })}
                </div>
              );
            })}
          </Spin>
        </div>
      </div>
      <div className="container img-chall">
        {challenge?.helpful_img ? <img src={generateURL(challenge?.helpful_img)} alt="img" /> : null}
      </div>
    </div>
  );
};

export default UpdateStats;
