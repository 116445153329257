import React from "react";
import "./password.scoped.scss";
import logo from "assets/img/logologo.png";

import { Form } from "@pankod/refine-antd";
import { PasswordButton } from "components/Buttons/Buttons";
import { InputBorderBottomWrapper, InputPrimary } from "components/Input";
import { useCustomMutation } from "@pankod/refine-core";
import { ArrowLeftOutlined, KeyOutlined } from "@ant-design/icons";
import routerProvider from "@pankod/refine-react-router-v6";
import { config } from "config";
const { Link } = routerProvider;

export default function ForgotPassword() {
  const { mutateAsync: forgotPassword, data, isLoading } = useCustomMutation();
  const handleSubmit = async (e: { email: string }) => {
    await forgotPassword({
      url: config.API_URL + "/auth/forgotPassword",
      values: {
        email: e.email.trim().toLowerCase(),
      },
      method: "post",
    });
  };
  const res = data?.data;
  return (
    <div className={"login-form pageload"}>
      <Form
        className="w-full max-w-xl px-10 py-12"
        onFinish={async (values) => {
          await handleSubmit(values);
        }}
      >
        <img src={logo} alt="Logo" className={"logo"} />

        {!res ? (
          <>
            <span className="key">
              <KeyOutlined style={{ fontSize: 40, color: "#8b2e2d" }} />
            </span>{" "}
            <h1 className={"title"}>Forgot password?</h1> <h3>No worries, we'll send you reset instructions.</h3>
            <InputBorderBottomWrapper>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <InputPrimary className={"input"} type="input" placeholder="Enter your email" />
              </Form.Item>
            </InputBorderBottomWrapper>
            <PasswordButton isLoading={isLoading} type={"string"} />
          </>
        ) : (
          <p>We've sent an email to reset your password</p>
        )}

        <div className="back">
          <Link to="/Login" style={{ color: "#00899f" }}>
            <span>
              <ArrowLeftOutlined />
            </span>
            Back to login
          </Link>
        </div>
      </Form>
    </div>
  );
}
