import React, {useState} from "react";
import "./challenge.scoped.scss";
import { css } from "@emotion/react";
import banner from "./../../assets/img/winter-banner.png";
import logoGL from "./../../assets/img/GLlogo-rm.png";
import { useMe } from "context/MeContext";
import ChallengeTables from "components/ChallengeTables";
import UpdateStats from "components/UpdateStats";
import ChallengeLeaderboard from "components/ChallengeLeaderboard";
import { useList, useOne } from "@pankod/refine-core";
import { ChallengeProvider } from "context/ChallengeContext";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";

const Challenge: React.FC = () => {
  const { me: user } = useMe();
  const [selectedChallenge, setSelectedChallenge] = React.useState<string | number>(0);
  const [sortOrder, setSortOrder] = useState('asc'); 
  const { challenge } = useCompany();
  const banner = challenge?.banner_image;

  const {
    data,
    refetch: refetchUserChallenge,
    isLoading,
    isFetching,
  } = useList({ 
    resource: "user-challenge", 
    config: { 
      filters: [
        {
          field: "challenge_id",
          operator: "eq",
          value: selectedChallenge,
        },
        {
          field: "userId",
          operator: "eq",
          value: user?.id,
        },
      ],
    },
    metaData: {
      join: ["team"],
    },
  });

  const { data: dataChallenge, isLoading: isLoadingChallenge } = useOne({
    resource: "challenge",
    id: selectedChallenge,
    queryOptions: {
      enabled: Boolean(selectedChallenge),
    },
  });
  const challengeInfo = dataChallenge?.data;
  console.log({ challengeInfo });
  const user_challenge = data?.data[0];
  return (
    <div className="challenge-page pageload">
      <div className="challenge-content">
        <div
          className="banner-chall"
          css={css`
            background: url(${generateURL(banner)}) no-repeat top center/cover;
            height: 80vh;
            margin: 0 auto;
            position: absolute; 
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
          `}
        >
          <div
            className="banner-container-chall"
            css={css`
              margin: auto;
              padding-top: 10%;
              text-align: center;
            `}
          >
            {/* <img
              className="logo-chall"
              src={logoGL}
              alt=""
              css={css`
                margin: auto;
                width: 10% !important;
              `}
            /> */}
            <div
              className="user-chall"
              css={css`
                color: ${challenge?.text_color1};
                font-size: 54px;
                font-weight: bold;
                font-family: "Poppins", sans-serif;
                margin: 50px auto 40px auto;
              `}
            >
              Hi, {user?.firstname || user?.email}!
            </div>
            <div
              className="intro-chall"
              css={css`
                color: ${challenge?.text_color1};
                font-size: 24px;
                font-family: "Poppins", sans-serif;
                margin-bottom: 80px;
                line-height: 30px;
                font-weight: 400;
              `}
            >
              Welcome to the {challenge?.season} Challenges.
              <br />
              Below you will find all of the information about how to participate.
            </div>
            <div
              className="month-chall"
              css={css`
                font-family: "Sacramento", cursive;
                color: ${challenge?.text_color2};
                font-size: 100px;
              `}
            >
              {challenge?.month}
            </div>
          </div>
        </div>
      </div>
      <ChallengeProvider selectedChallenge={selectedChallenge}>
        <div
          css={css`
            margin: 0 !important;
            padding: 0 !important;
          `}
        >
          <ChallengeTables
            setSelectedChallenge={setSelectedChallenge}
            selectedChallenge={selectedChallenge}
            user_challenge={user_challenge}
            refetchUserChallenge={refetchUserChallenge}
            challenge={challengeInfo}
          />
        </div>
        <div
          css={css`
            margin: 0 !important;
          `}
        >
          <UpdateStats
            user_challenge={user_challenge}
            selectedChallenge={selectedChallenge}
            isLoading={isLoading || isFetching}
            refetchUserChallenge={refetchUserChallenge}
            challenge={challengeInfo}
            isLoadingChallenge={isLoadingChallenge}
          />
        </div>
        <div
          css={css`
            margin: 0!important;
          `}
        >
          <ChallengeLeaderboard />
        </div>
      </ChallengeProvider>
    </div>
  );
};

export default Challenge;
 