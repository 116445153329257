import React from "react";
import "./Button.scoped.scss";
import { Spin } from "@pankod/refine-antd";

interface Props {
  type: string;
  isLoading?: boolean;
}
export const LoginButton: React.FC = (props) => {
  return (
    <div>
      <button className="button">
        LOGIN
        <div className="button__horizontal" />
        <div className="button__vertical" />
      </button>
    </div>
  );
};
export const SignupButton: React.FC<Props> = ({ type }) => {
  return (
    <div>
      <button className="button" type="submit">
        SIGN UP
        <div className="button__horizontal" />
        <div className="button__vertical" />
      </button>
    </div>
  );
};
export const PasswordButton: React.FC<Props> = ({ type, isLoading }) => {
  return (
    <Spin spinning={isLoading}>
      <div>
        <button disabled={isLoading} className="button" type="submit">
          Reset password
          <div className="button__horizontal" />
          <div className="button__vertical" />
        </button>
      </div>
    </Spin>
  );
};
