import { Col, Create, DatePicker, Form, Input, Row, Select, useForm } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { optionsGender } from "pages/Auth/SocialRegister/SocialRegister";
import { RoleOptions } from "./edit";
export const UserCreate: React.FC = () => {
  const { push } = useNavigation();
  const { isAdmin } = useMe();

  const { formProps, saveButtonProps } = useForm({
    resource: "users",
    onMutationSuccess: () => {
      push("/admin/user");
    },
    submitOnEnter: true,
    redirect: false,
  });

  return (
    <Create title="Create User" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <Form.Item
              label={"Firstname"}
              name="firstname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Lastname"}
              name="lastname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Email"}
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={"Username"}
              name="username"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Date of birth"}
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker className="w-full" />
            </Form.Item>

            <Form.Item
              label={"Gender"}
              name="gender"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={optionsGender} placeholder="Birth Sex" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <div className="flex space-x-2">
              <Form.Item
                label={"Role"}
                name="role"
                rules={[
                  {
                    required: false,
                  },
                ]}
                className="w-full"
              >
                <Select disabled={isAdmin} options={RoleOptions} />
              </Form.Item>
              <Form.Item
                label={"Employer code"}
                name="employeeCode"
                rules={[
                  {
                    required: false,
                  },
                ]}
                className="w-full"
              >
                {/* <Input addonAfter={`${companySelected?.code || ''}${employeeCode || ''}`} /> */}
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
