import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import React from "react";

export const Dimension: React.FC = () => {
  const { tableProps } = useTable({
    resource: "dimension",
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="dimension" />
          </div>
        );
      },
      width: "10%",
    },
  ];
  return (
    <List title="Dimension" canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
  );
};
