import React from "react";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";

interface PaginationProps {
  current: number;
  totalPages: number;
  setCurrent: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ current, totalPages, setCurrent }) => {
  const { brand, hra_summary } = useCompany();
  if (totalPages === 0) {
    return null;
  }
  const generatePages = () => {
    const pages = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1, 2);

      if (current > 4) {
        pages.push("...");
      }

      for (let i = Math.max(3, current - 1); i <= Math.min(totalPages - 2, current + 1); i++) {
        pages.push(i);
      }

      if (current < totalPages - 3) {
        pages.push("...");
      }

      pages.push(totalPages - 1, totalPages);
    }

    return pages;
  };

  return (
    <div className="pagination-controls prev-group" css={css`
          display: flex;
          margin: auto;
          justify-content: flex-end; 
          width: 100%;
          button:focus {
            outline: revert;
          }
          button:disabled {
            color: #d8d8d8 !important;
            cursor: not-allowed;
          }
          .prev {
            width: 40px;
            height: 40px;
            text-align: center;
            transition: background-color 0.3s;
            border-radius: 5px;
            color: ${brand?.color_primary};
            margin-left: 10px;
          }
          .number {
            pointer-events: none;
            background-color: ${brand?.color_primary};
            color: white;
          }
          .prev-button {
            font-weight: 700;
            cursor: pointer;
            background-color: transparent;
            &:hover {
              border: 2px solid ${brand?.color_primary};
            }
          }
          button {
            border: 1px solid rgba(128, 128, 128, 0.2);
          }
      @media (max-width: 768px) {
        .prev {
          width: 30px !important;
          height: 30px !important;
          margin-left: 5px !important;
        }
      }
    `}>
      <button
        className="prev prev-button previous"
        onClick={() => setCurrent(current - 1)}
        disabled={current === 1}
      >
        {"<"}
      </button>

      {generatePages().map((page, index) => (
        <button
          key={index}
          onClick={() => page !== "..." && setCurrent(page as number)}
          className={`prev ${current === page ? "number active" : ""}`}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}

      <button
        className="prev prev-button next"
        onClick={() => setCurrent(current + 1)}
        disabled={current === totalPages}
      >
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
