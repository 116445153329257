import React, { useState, useEffect } from "react";
import { useCompany } from "../../context/CompanyContext";
import { css, Global } from "@emotion/react";
import { List } from "antd";
import "./resources.scoped.scss";
import { Button, Menu, Popover, Select } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useList } from "@pankod/refine-core";
import { ResourceType } from "types/resource";
import { config } from "config";

const Resources2024: React.FC = () => {
  const { data } = useList({
    resource: "resource",
    metaData: {
      join: ["dimension"],
    },
  });
  const wellnessDimensions = data?.data;
  const { brand } = useCompany();
  const [collapsed, setCollapsed] = useState(false);
  const [dimensionClick, setDimensionClick] = useState("");
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function handleClick(id: string) {
    setDimensionClick(id);
  }
  const wellnessData = React.useMemo(() => {
    return wellnessDimensions?.find((dim) => dim.id?.toString() === dimensionClick.toString());
  }, [dimensionClick, wellnessDimensions]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDimensionClick("2");
      } else {
        setDimensionClick("");
      }
    };

    // Initial check
    handleResize();

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${brand?.color_primary};
            margin-top: 0;
            padding-top: 0 !important;
          }
        `}
      />
      <div
        className="resources-page pageload"
        css={css`
          h2 {
            color: ${brand?.color_primary};
          }
        `}
      >
        <div className="">
          <section className="main-title container">
            <h1 css={css`line-height: normal`}>THE 8 DIMENSIONS OF WELLNESS</h1>
            <p>Click on each dimension of wellness for resources and tips</p>
          </section>

          <div className="circle-menu container">
            <ul>
              {wellnessDimensions?.map((item: any) => (
                <li key={item?.id}>
                  <Popover
                    placement={item?.placement}
                    title={item?.dimension?.name}
                    content={<div dangerouslySetInnerHTML={{ __html: item.description }} />}
                    overlayStyle={{ width: "30vw" }}
                  >
                    <a
                      className={item?.dimension?.name?.toLowerCase()}
                      href="#dimension-content"
                      onClick={() => setDimensionClick(item?.id)}
                    >
                      {item?.dimension?.name?.charAt(0).toUpperCase() + item?.dimension?.name?.slice(1).toLowerCase()}
                    </a>
                  </Popover>
                </li>
              ))}
            </ul>
          </div>

          <div
            className="main-content container j-between"
            id="dimension-content"
            css={css`
              ${dimensionClick === "" ? "display:none !important;" : ""}
              @media screen and (max-width: 768px) {
                ${dimensionClick === "" ? "display:block !important;" : ""}
              }
            `}
          >
            <div className="side hide">
              <div className="side-menu" style={{ width: "100%", height: 500 }}>
                <Button type="primary" onClick={toggleCollapsed} style={{ margin: "0 0 16px 0" }}>
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                <Menu
                  selectedKeys={[dimensionClick]}
                  mode="inline"
                  theme="light"
                  inlineCollapsed={collapsed}
                  onClick={({ key }) => {
                    handleClick(key);
                  }}
                >
                  {wellnessDimensions?.map((item) => <Menu.Item key={item?.id} className="menu-item" css={css`
                    @media screen and (max-width: 768px) {
                    padding-left: 5px !important;
                    }
                    `}>
                    {item?.dimension?.name}
                  </Menu.Item>)}
                </Menu>
              </div> 
            </div>

            <div className="mobile">
              <div className="mobile-menu">
                <Select style={{minWidth: 150, margin:"10px auto"}}
                  value={dimensionClick}
                  onChange={(key:any ) => {
                    handleClick(key);
                }}>
                  {wellnessDimensions?.map((item) => <Select.Option key={item?.id} className="menu-item" css={css`
                    margin-bottom: 20px;
                  `}>
                    {item?.dimension?.name}
                  </Select.Option>)}
                </Select>
              </div>
            </div>

            <div className="content container">
              <div
                css={css`
                  color: black;
                `}
              >
                <h2 className="">{wellnessData?.dimension?.name}</h2>
                <List
                  className="resources-list container"
                  itemLayout="horizontal"
                  dataSource={wellnessData?.resource_details}
                  renderItem={(resource: any) => {
                    const url = resource?.type === ResourceType.PDF ? config.ASSET_URL + resource?.url : resource?.url;
                    return (
                      <List.Item className="container"> 
                        <List.Item.Meta
                          className="container"
                          title={
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              &#10023; {resource?.title}{" "}
                            </a>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources2024;
