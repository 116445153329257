import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import IconList from "./list";

export const Icons: React.FC = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "District51",
      children: <IconList />,
    },
    {
      key: "2",
      label: "Hilltop",
      children: <IconList />,
    },
    {
      key: "3",
      label: "Marshall",
      children: <IconList />,
    },
  ];
  return (
    <List title="Celebration Icons">
      <div className="bg-white p-4">
        <IconList />
      </div>
    </List>
  );
};
