import React, { PropsWithChildren } from "react";

interface CardAdminProps {
  title: string;
}

export const CardAdmin: React.FC<PropsWithChildren<CardAdminProps>> = ({ title, children }) => {
  return (
    <div className="mt-3 border border-gray-300 border-solid p-4" style={{background:"white"}}>
      <h3>{title}</h3>
      {children}
    </div>
  );
};
