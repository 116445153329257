export interface IUserUpdate {
  firstname?: string;
  lastname?: string;
  email?: string;
  dateOfBirth?: Date;
  password?: string;
  gender?: string;
  username?: string;
}
export enum Role {
  ADMIN = "Admin",
  MANAGEMENT = "Management",
  MEMBER = "Member",
  GUEST = "Guest",
}

export interface UserSettings {
  showInDashboard?: boolean;
}
export interface IUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  password?: string;
  companyId?: string | null;
  createdAt: Date;
  dateOfBirth?: Date | null;
  deletedAt?: Date | null;
  icon?: string | null;
  employeeCode?: string | null;
  gender?: string | null;
  updatedAt?: Date | null;
  username: string;
  activityPoint: number;
  trophy: number;
  isChangedIcon?: boolean;
  role: Role;
  userSettings?: UserSettings;
  isOps?: boolean;
  hideMeDashboard?: boolean;
  company?: any;
}
