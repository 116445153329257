import React, { useEffect } from "react";
import "./hraresults.scoped.scss";
import routerProvider from "@pankod/refine-react-router-v6";
import { useCompany } from "context/CompanyContext";
import { useMe } from "context/MeContext";
import { Button } from "antd";
import { HRASection } from "../../components/HRA/index";
import { RiskAreaImage } from "components/Risk";
import { css } from "@emotion/react";

const { Link } = routerProvider;
const HRAresults: React.FC = () => {
  const { path: company_path, brand } = useCompany();
  const { myHRA } = useMe();
  useEffect(() => {
    document.body.classList.add("dashboard");
    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  return (
    <div>
      <div className="container hra-report">
        <div
          className="page-title"
          css={css`
            color: ${brand?.color_primary} !important;
            font-size: 40px;
            text-align: center;
            font-family: "Tilt Warp", cursive;
            line-height: 40px;
            padding: 50px 0;
            @media (max-width: 768px) {
              padding: 20px 0 !important;
              font-size: 30px;
              margin-top: 10vh !important;
            }
          `}
        >
          YOUR HEALTH RISK REPORT
        </div>

        {/* HRA SUMMARY */}
        <div className={`chart container`} style={{ marginTop: 3 }}>
          {typeof myHRA !== "object" ? (
            <Link to={`/${company_path}/hra`} className="disabled-chart">
              <div className="disabled-group">
                <p>Curious about diving deeper into your health stats? Take the HRA!</p>
                <Button size="large">Let's Go!</Button>
              </div>
            </Link>
          ) : null}
          <HRASection />
        </div>

        {/* RISK AREAS & IMAGE */}
        <div className={`chart container`}>
          <RiskAreaImage />
        </div>
      </div>
    </div>
  );
};

export default HRAresults;
