import { Form } from "@pankod/refine-antd";
import { ResultType } from "./ResultType";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { FormListFieldData } from "antd/es/form/FormList";
import React from "react";
import { DataType } from "types/challenge";

interface ListResultProps {
  field: FormListFieldData;
}
export const ListResult: React.FC<ListResultProps> = ({ field }) => {
  const type = Form.useWatch(["stats", field.name, "data_type"]);
  const onlyOneOption = [DataType["Yes/No"], DataType.Date, DataType.FreeText];
  return (
    <Form.List name={[field.name, "results", type]}>
      {(fieldsR, { add: addR, remove: removeR }, { errors: errorsR }) => (
        <div>
          {fieldsR.map((fieldR) => {
            return (
              <div key={fieldR.key} className="relative border-solid border-2 border-gray-200 rounded p-2 mt-2">
                <ResultType field={field} fieldR={fieldR} />

                <CloseCircleFilled
                  className="absolute right-[5px] top-[10px] text-red-400 text-[20px] cursor-pointer"
                  onClick={() => {
                    removeR(fieldR.name);
                  }}
                />
              </div>
            );
          })}
          <p className="flex justify-end h-[22px] text-red-700">{errorsR}</p>

          {((Boolean(type) && !onlyOneOption.includes(type)) ||
            (onlyOneOption.includes(type) && fieldsR.length === 0)) && (
            <div onClick={() => addR()} className="cursor-pointer flex justify-end">
              <PlusOutlined className="mr-2" />
              Add response
            </div>
          )}
        </div>
      )}
    </Form.List>
  );
};
