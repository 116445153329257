import { CloseCircleFilled } from "@ant-design/icons";
import { Form, FormInstance, FormListFieldData, Input, Select } from "antd";
import { UploadFiles } from "components/Upload/UploadFile";
import React from "react";
import { ResourceType } from "types/resource";

interface ResourceDetailsProps {
  field: FormListFieldData;
  form?: FormInstance<{}>;
  setLoading: (value: boolean) => void;
  loading: boolean;
  remove: (key: number) => void;
}

export const resourceOps = [
  {
    value: ResourceType.Web,
    label: ResourceType.Web,
  },
  {
    value: ResourceType.PDF,
    label: ResourceType.PDF,
  },
];

export const ResourceDetails: React.FC<ResourceDetailsProps> = ({ field, setLoading, form, loading, remove }) => {
  const [files, setFiles] = React.useState<string[]>([]);
  const type = Form.useWatch(["resource_details", field.name, "type"]);
  const url = Form.useWatch(["resource_details", field.name, "url"]);
  React.useEffect(() => {
    if (type === ResourceType.PDF) {
      form?.setFieldValue(["resource_details", field.name, "url"], files[0]);
    }
  }, [field.name, files, form, type]);

  const onSetFiles = (proof: string) => {
    return setFiles((prev) => {
      return [...prev, proof];
    });
  };
  const onRemoveFile = (file: string) => {
    return setFiles((prev) => {
      return prev.filter((f) => f !== file);
    });
  };

  return (
    <div className="relative border border-gray-200 border-solid rounded p-4 mb-3">
      <Form.Item name={[field.name, "title"]} label={"Title"} className="w-1/2">
        <Input />
      </Form.Item>
      <Form.Item
        className="w-1/2 "
        label={"Type"}
        name={[field.name, "type"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select options={resourceOps} />
      </Form.Item>

      {type === ResourceType.PDF ? (
        <Form.Item name={"url"} className="w-1/2">
          <UploadFiles
            setLoading={setLoading}
            loading={loading}
            files={url ? [url] : []}
            onRemoveFile={onRemoveFile}
            callback={onSetFiles}
            btnName="Upload PDF"
            normal={false}
            maxCount={1}
          />
        </Form.Item>
      ) : (
        <Form.Item name={[field.name, "url"]} label="URL" required className="w-1/2">
          <Input />
        </Form.Item>
      )}
      <CloseCircleFilled
        className="absolute right-[15px] top-[15px] text-red-400 text-[20px] cursor-pointer"
        onClick={() => {
          remove(field.name);
        }}
      />
    </div>
  );
};

