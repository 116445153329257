import React, { useState } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Form, FormInstance, Input, InputNumber, Tooltip } from "@pankod/refine-antd";
import { AnswerList } from "components/AnswerList";
import { UserAnswerList } from "pages/HRA";
import { useCompany } from "context/CompanyContext";

import "./questionRes.scoped.scss";
import { FreeTextInput, GenerateQuestionList } from "components/Question/GenerateQuestionList";

interface GenerateQuestionListProps {
  questions?: any[];
  isChild?: boolean;
  userAnswerList: UserAnswerList[];
  setUserAnswerList: React.Dispatch<React.SetStateAction<UserAnswerList[]>>;
  form: FormInstance<any>;
}

export const GenerateQuestionListRes: React.FC<GenerateQuestionListProps> = ({
  questions,
  isChild,
  userAnswerList,
  setUserAnswerList,
  form,
}) => {
  const { hra_summary } = useCompany();
  const [activeQuestion, setActiveQuestion] = useState(1);
  const [step, setStep] = useState(1);
  const questionHasChild = questions?.filter((q) => q.type === "Group") || [];
  const question = questions?.find((item) => item.name === activeQuestion);
  const len = userAnswerList.length;

  const showFreeTextArea = question?.answer_detail?.items?.length === 1;
  const type = question?.type;
  const childQuestions = question?.childQuestions?.sort((a: any, b: any) => a.name - b.name);
  const childQuestionsCount: number = childQuestions?.length || 0;
  const hasChildQuestions = childQuestionsCount > 0;
  const isEnd = activeQuestion === len + questionHasChild.length;
  React.useEffect(() => {
    if (hasChildQuestions) {
      setStep(childQuestionsCount + 1);
    } else {
      setStep(1);
    }
  }, [hasChildQuestions, activeQuestion]);
  const isError = Boolean(form.getFieldError("question-ft")[0] || form.getFieldError(`question${question?.id}`)[0]);

  const onClickNext = React.useCallback(() => {
    if (isEnd) {
      return;
    }
    if (isError) {
      return;
    }
    setActiveQuestion((prev) => {
      if (hasChildQuestions) {
        return prev + step;
      }
      return prev + 1;
    });
  }, [hasChildQuestions, isEnd, isError, step]);
  const onClickPrev = () => {
    setActiveQuestion((prev) => {
      if (hasChildQuestions) {
        return prev - step;
      }
      return prev - 1;
    });
  };

  const onFreeTextChange = (value: string, id: number) => {
    setUserAnswerList((curr) => {
      return curr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: value,
          };
        }
        return item;
      });
    });
  };

  return (
    <div
      css={css`
        border: 1px solid ${hra_summary?.main_hra_color};
        border-radius: 16px;
        padding: 11px;
        margin-top: 15px;
      `}
    >
      {/* <h2
        css={css`
          font-size: 18px !important;
          font-weight: 500;
          margin: 0;
          line-height: 1.3 !important;
        `}
      >
        {question?.description}
      </h2> */}
      <ul
        css={css`
          .s-a {
            background-color: ${hra_summary?.background_color} !important;
            border: 1px solid ${hra_summary?.main_hra_color} !important;
          }
        `}
      >
        <li
          css={css`
            text-decoration: none;
            list-style: none;
            color: #2d264b;
            font-size: 16px;
            background: #ffffff;
            border: 1px solid #eaeaea;
            border-radius: 16px;
            padding: 11px;
            margin-top: 15px;
          `}
        >
          <div className="question">
            {isChild ? <h5>{question?.description}</h5> : <h3>{question?.description}</h3>}
            {!isChild && (
              <Tooltip
                title={
                  <>
                    <div className="tooltip">{question?.question_source.description}</div>{" "}
                    <a
                      href={question?.question_source.additional}
                      target="_blank"
                      rel="noreferrer"
                      className="learnmore"
                      css={css`
                        color: ${hra_summary?.main_hra_color} !important;
                        background-color: ${hra_summary?.background_color} !important;
                        &:hover {
                          background-color: ${hra_summary?.main_hra_color} !important;
                        }
                      `}
                    >
                      {" "}
                      Learn more.
                    </a>
                  </>
                }
              >
                <Button className="why-ask-group">
                  <QuestionCircleFilled
                    className="question-mark-icon"
                    css={css`
                      background-color: ${hra_summary?.main_hra_color} !important;
                      color: ${hra_summary?.background_color} !important;
                    `}
                  />
                  <div
                    className="why-ask"
                    css={css`
                      color: ${hra_summary?.main_hra_color} !important;
                    `}
                  >
                    Why are we asking this?
                  </div>
                </Button>
              </Tooltip>
            )}
          </div>

          <div className="answer-group">
            <div className="flex flex-col w-full">
              {showFreeTextArea && <FreeTextInput item={question} onFreeTextChange={onFreeTextChange} />}
              {!showFreeTextArea && (
                <AnswerList
                  key={question?.id}
                  form={form}
                  id={question?.id}
                  setUserAnswerList={setUserAnswerList}
                  type={type}
                  data={question?.answer_detail.items}
                  userAnswerList={userAnswerList}
                />
              )}
            </div>
          </div>
          {childQuestions?.length > 0 && (
            <GenerateQuestionList
              form={form}
              setUserAnswerList={setUserAnswerList}
              questions={childQuestions}
              isChild
            />
          )}
        </li>
      </ul>
      <div
        className="flex-right"
        css={css`
          button {
            background-color: ${hra_summary?.main_hra_color};
          }
          .finishButton {
            background-color: ${hra_summary?.uncomplete_button_color} !important;
          }
        `}
      >
        <button onClick={onClickPrev} disabled={activeQuestion === 1}>
          Prev
        </button>
        <button
          onClick={onClickNext}
          className={isEnd ? "finishButton" : ``}
          style={{ marginLeft: "10px" }}
          disabled={isError}
        >
          {isEnd ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default GenerateQuestionListRes;
