import { useForm, Form, Input, Row, Col, Create } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import React from "react";

export const DimensionCreate: React.FC = () => {
  const { push } = useNavigation();
  const { formProps, saveButtonProps } = useForm({
    resource: "dimension",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/dimension");
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <Form.Item
              label={"Name"}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Order"}
              name="order"
              rules={[
                {
                  required: true,
                  min: 0,
                },
              ]}
            >
              <Input min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
