import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { Checkbox, Form, FormInstance, Input, Radio } from "@pankod/refine-antd";
import { UserAnswerList } from "pages/HRA";
import React from "react";
const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;
interface AnswerListProps {
  type: string;
  data?: any;
  setUserAnswerList: React.Dispatch<React.SetStateAction<UserAnswerList[]>>;
  userAnswerList?: UserAnswerList[];
  id: number;
  form: FormInstance<any>;
}

export const AnswerList: React.FC<AnswerListProps> = ({ type, data, setUserAnswerList, id, form, userAnswerList }) => {
  switch (type) {
    case "SingleChoice":
      return (
        <SingleChoice
          form={form}
          id={id}
          setUserAnswerList={setUserAnswerList}
          userAnswerList={userAnswerList}
          data={data}
        />
      );
    case "MultipleChoice":
      return (
        <MultipleChoice
          form={form}
          id={id}
          setUserAnswerList={setUserAnswerList}
          userAnswerList={userAnswerList}
          data={data}
        />
      );
    default:
      return <div />;
  }
};

const SingleChoice: React.FC<Omit<AnswerListProps, "type">> = ({
  data,
  setUserAnswerList,
  userAnswerList,
  id,
  form,
}) => {
  const [checkedName, setCheckedName] = React.useState("");
  const { hra_summary } = useCompany();
  const _answered = userAnswerList?.find((a) => a.id === id)?.choice[0];

  React.useEffect(() => {
    if (!_answered) return;
    setCheckedName(_answered);
  }, [_answered]);
  const onChange = (value: any, id: number, text?: string) => {
    form.setFieldValue(`question${id}`, "yep");
    form.validateFields([`question${id}`]);
    setUserAnswerList((curr) => {
      return curr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            choice: [value],
            text: text || item.text,
          };
        }
        return item;
      });
    });
  };
  const onChangeText = (value: string, id: number, text?: string) => {
    onChange(value, id, text);
    setCheckedName(value || "");
  };
  return (
    <div
      css={css`
        .h-zero {
          .ant-form-item-control-input {
            min-height: 0px !important;
          }
        }
        .ant-radio-checked .ant-radio-inner {
          border-color: ${hra_summary?.main_hra_color};
          background-color: ${hra_summary?.main_hra_color};
        }
      `}
    >
      <Radio.Group
        onChange={(e) => {
          onChange(e.target.value, id);
          setCheckedName(e.target.value);
        }}
        value={checkedName}
      >
        <div className="flex flex-col">
          {data?.map((answer: any) => {
            return (
              <Radio key={answer.name} value={answer.name}>
                <div className="answer" style={{ margin: "0.3rem 0", fontSize: "16px" }}>
                  <span className="text">
                    {answer.description}
                    {answer.description !== "Yes (Fill in the blank your primary care provider)" && answer.extra ? (
                      <TextArea
                        onChange={(e) => onChangeText(answer.name, id, e.target.value)}
                        style={{ height: 60, marginBottom: 10 }}
                        showCount
                        maxLength={1000}
                        css={css`
                        width: 400px; 
                        @media only screen and (max-width: 577px) {
                          width: 250px;
                        }
                        `}
                      />
                    ) : null}
                    {answer.description === "Yes (Fill in the blank your primary care provider)" && answer.extra ? (
                      <Input
                        onChange={(e) => onChangeText(answer.name, id, e.target.value)}
                        style={{ width: "300px", marginLeft: 20 }}
                        css={css`
                          &:hover {
                            border: 1px solid ${hra_summary?.main_hra_color} !important;
                          }
                          @media only screen and (max-width: 577px) {
                            width: auto !important;
                            margin-left: 0 !important;
                          }
                        `}
                      />
                    ) : null}
                  </span>
                </div>
              </Radio>
            );
          })}
        </div>
      </Radio.Group>
      <Form.Item
        className="h-[0px] h-zero"
        name={`question${id}`}
        rules={[
          {
            required: true,
            message: "This question is required!",
          },
        ]}
      >
        <Input hidden className="h-[0px]" />
      </Form.Item>
    </div>
  );
};

const MultipleChoice: React.FC<Omit<AnswerListProps, "type">> = ({ data, setUserAnswerList, id }) => {
  const onChange = (value: any, id: number) => {
    setUserAnswerList((curr) => {
      return curr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            choice: value,
          };
        }
        return item;
      });
    });
  };
  const options = data?.map((item: any) => {
    return {
      value: item.name,
      label: item.description,
    };
  });
  return (
    <Form.Item
      name={`question${id}`}
      rules={[
        {
          required: true,
          message: "This question is required!",
        },
      ]}
    >
      <CheckboxGroup
        onChange={(e) => {
          onChange(e, id);
        }}
        css={css`
          .ant-checkbox-wrapper {
            margin-inline-start: 0px;
            margin: 0.3rem 0;
            font-size: 16px;
          }
        `}
        className="flex flex-col"
        options={options}
      />
    </Form.Item>
  );
};
