import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Icon } from "@pankod/refine-antd";
import React from "react";

export const FacebookIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  const FacebookSvg = () => (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1371"
      width="100%"
      height="100%"
    >
      <path
        d="M756 6.848l0 150.848-89.728 0q-49.152 0-66.272 20.576t-17.152 61.728l0 108 167.424 0-22.272 169.152-145.152 0 0 433.728-174.848 0 0-433.728-145.728 0 0-169.152 145.728 0 0-124.576q0-106.272 59.424-164.864t158.272-58.56q84 0 130.272 6.848z"
        p-id="1372"
      ></path>
    </svg>
  );
  return <Icon component={FacebookSvg} {...props} />;
};

export const GoogleIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  const GoogleSvg = () => (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2684"
      width="100%"
      height="100%"
    >
      <path
        d="M915.2 448l-4.2-17.8H524V594h231.2c-24 114-135.4 174-226.4 174-66.2 0-136-27.8-182.2-72.6-47.4-46-77.6-113.8-77.6-183.6 0-69 31-138 76.2-183.4 45-45.2 113.2-70.8 181-70.8 77.6 0 133.2 41.2 154 60l116.4-115.8c-34.2-30-128-105.6-274.2-105.6-112.8 0-221 43.2-300 122C144.4 295.8 104 408 104 512s38.2 210.8 113.8 289c80.8 83.4 195.2 127 313 127 107.2 0 208.8-42 281.2-118.2 71.2-75 108-178.8 108-287.6 0-45.8-4.6-73-4.8-74.2z"
        p-id="2685"
      ></path>
    </svg>
  );
  return <Icon component={GoogleSvg} {...props} />;
};

export const MicrosoftIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  const MicrosoftSvg = () => (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3733"
      width="100%"
      height="100%"
    >
      <path
        d="M85.333333 128h384v384H85.333333V128m384 810.666667H85.333333v-384h384v384M896 128v384h-384V128h384m0 810.666667h-384v-384h384v384z"
        fill=""
        p-id="3734"
      ></path>
    </svg>
  );
  return <Icon component={MicrosoftSvg} {...props} />;
};

export function IconPhoneCall() {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
    >
      <path d="M15.05 5A5 5 0 0119 8.95M15.05 1A9 9 0 0123 8.94m-1 7.98v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
    </svg>
  );
}

export function IconEnvelope() {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em">
      <path d="M0 128c0-35.35 28.65-64 64-64h384c35.3 0 64 28.65 64 64v256c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128zm48 0v22.1l172.5 141.6c20.6 17 50.4 17 71 0L464 150.1v-23c0-7.9-7.2-16-16-16H64c-8.84 0-16 8.1-16 16v.9zm0 84.2V384c0 8.8 7.16 16 16 16h384c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.6 31.5-132.9 0L48 212.2z" />
    </svg>
  );
}

export function IconLocationOutline() {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M304 192 A48 48 0 0 1 256 240 A48 48 0 0 1 208 192 A48 48 0 0 1 304 192 z"
      />
    </svg>
  );
}
