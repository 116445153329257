import { Form, Input, Select } from "@pankod/refine-antd";
import { dataTypeOps } from "../create";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { ListResult } from "./ListResult";

export const ListStats: React.FC = () => {
  return (
    <Form.List name={"stats"}>
      {(fields, { add, remove }, { errors }) => (
        <div>
          {fields.map((field) => {
            return (
              <div key={field.key} className="relative border-solid border-2 border-gray-200 p-3 rounded mt-3">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Title is required!",
                    },
                  ]}
                  label={`Title`}
                  name={[field.name, "title"]}
                  className="w-full pr-[30px]"
                >
                  <Input className="font-normal" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Description is required!",
                    },
                  ]}
                  label={`Description`}
                  name={[field.name, "description"]}
                  className="w-full pr-[30px]"
                >
                  <Input.TextArea className="font-normal" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Description is required!",
                    },
                  ]}
                  label={`Data Type`}
                  name={[field.name, "data_type"]}
                  className="w-full pr-[30px]"
                >
                  <Select options={dataTypeOps} className="font-normal" />
                </Form.Item>

                <Form.Item hidden name={[field.name, "order"]} className="w-full pr-[30px]">
                  <Input className="font-normal" />
                </Form.Item>
                <h4>Results</h4>
                <ListResult field={field} />

                <CloseCircleFilled
                  className="absolute right-[5px] top-[35px] text-red-400 text-[20px] cursor-pointer"
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </div>
            );
          })}
          <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

          {fields.length < 30 && (
            <div onClick={() => add()} className="cursor-pointer flex justify-end">
              <PlusOutlined className="mr-2" />
              Add data element
            </div>
          )}
        </div>
      )}
    </Form.List>
  );
};
