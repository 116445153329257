const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],       
  ['link', 'image'],
  [{ 'header': 1 }, { 'header': 2 },],               
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],         
  //[{ 'size': ['small', false, 'large', 'huge'] }],  
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          
  //[{ 'font': [] }],
  [{ 'align': [] }],
];

export default toolbarOptions;